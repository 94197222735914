import React from 'react'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import TableSkeletonLoader from '../common/TableSkeleton'
import NoDataTable from '../common/NoDataTable'
import ErrorTable from '../common/ErrorTable'
import { useDeleteUsefulFile, useListenToUsefulFiles, useUsefulFiles } from '../../hooks/useUsefulFile'
import { UsefulFile } from '../../types/usefulFiles.types'
import ActionButton from '../../components/Button/Button'
import ViewDownloadSection from './ViewDownloadSection'

const UsefulFilesUploaderPastList: React.FC = () => {
  const { t } = useTranslation()

  // Start the real-time listener
  useListenToUsefulFiles()

  // Get the list of past purchase requests
  const { data: usefulFiles, isLoading, error } = useUsefulFiles()

  const deleteFile = useDeleteUsefulFile()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error(error)
    return (
      <ErrorTable
        title={t('usefulFiles.uploaderList.error.title')}
        description={t('usefulFiles.uploaderList.error.description')}
        message={t('usefulFiles.uploaderList.error.message')}
      />
    )
  }

  if (!usefulFiles || usefulFiles.length === 0) {
    return (
      <NoDataTable
        title={t('usefulFiles.uploaderList.noUsefulFiles.title')}
        description={t('usefulFiles.uploaderList.noUsefulFiles.description')}
        message={t('usefulFiles.uploaderList.noUsefulFiles.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        t('usefulFiles.uploaderList.header.documentName'), // document name
        t('usefulFiles.uploaderList.header.uploadedDate'), // uploaded date
        t('usefulFiles.uploaderList.header.uploadedBy'), // uploaded by
        '', // empty cell
        '', //  download action
        '', //  delete action
      ]}
      data={usefulFiles}
      getRowId={(usefulFile: UsefulFile) => usefulFile.id}
      renderRow={(usefulFile: UsefulFile) => [
        usefulFile.name, // document name
        timestampToDate(usefulFile.createdAt), // uploaded date
        usefulFile.user?.name, // uploaded by
        '', // empty cell
        <ViewDownloadSection key={`download-${usefulFile.id}`} fileUrl={usefulFile.url} />, // download action
        <ActionButton key={`delete-button-${usefulFile.id}`} buttonType="reject" onClick={() => deleteFile(usefulFile.id)}>
          {t('usefulFiles.uploaderList.delete')}
        </ActionButton>, // delete action
      ]}
    />
  )
}

export default UsefulFilesUploaderPastList
