import React, { useEffect, createContext, ReactNode, useState } from 'react'
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
import { useSetAtom } from 'jotai'
import { userAtom } from '../stores/userAtom'
import { auth } from '../services/firebase.service'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { UserDetails } from '../types/userDetails.types'
import { FIREBASE_COLLECTIONS } from '../constants/firebase.constants'

interface AuthContextType {
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  loading: boolean
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const setAtomUser = useSetAtom(userAtom)
  const [loading, setLoading] = useState(true)
  const db = getFirestore()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userDoc = await getDoc(doc(db, FIREBASE_COLLECTIONS.USERS, firebaseUser.uid))
        if (userDoc.exists()) {
          setAtomUser({ ...firebaseUser, ...userDoc.data() } as UserDetails)
        } else {
          setAtomUser(firebaseUser)
        }
      } else {
        setAtomUser(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [setAtomUser, db])

  const login = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(auth, email.trim(), password)
    const firebaseUser = userCredential.user
    const userDoc = await getDoc(doc(db, FIREBASE_COLLECTIONS.USERS, firebaseUser.uid))
    if (userDoc.exists()) {
      setAtomUser({ ...firebaseUser, ...userDoc.data() })
    } else {
      setAtomUser(firebaseUser)
    }
  }

  const logout = async () => {
    await signOut(auth)
    setAtomUser(null)
  }

  return <AuthContext.Provider value={{ login, logout, loading }}>{children}</AuthContext.Provider>
}
