import { Complaint, ComplaintFirestoreData, ComplaintFormData } from '../types/complaint.types'
import { FIREBASE_COLLECTIONS } from '../constants/firebase.constants'
import { useListenToFirestoreQuery, useWriteDocument } from './useFirestore'
import { QueryKeys } from '../constants/queryKeys.constants'
import { getDocsAndEnhanceWithUserDetails, orderCreatedAt } from '../services/firestore.service'
import { useQuery } from '@tanstack/react-query'
import { userAtom } from '../stores/userAtom'
import { appendWithUserId } from '../utils/form.utils'
import { useAtom } from 'jotai'

/**
 * Custom hook to submit a complaint.
 *
 * This hook utilizes the `useWriteDocument` hook to handle the submission
 * of complaints to the Firestore database.
 *
 * @returns {Mutation} A mutation object from react-query that can be used to submit a complaint.
 *
 * @example
 * const submitComplaint = useSubmitComplaint();
 *
 * submitComplaint.mutate(
 *     { data: complaintData },
 *     { onSuccess: () => {}, onError: () => {} }
 * )
 */
export const useSubmitComplaint = () => {
  const [user] = useAtom(userAtom)
  if (!user) {
    throw new Error('User not found')
  }
  const transformData = (data: ComplaintFormData) => appendWithUserId(data, user)
  return useWriteDocument<ComplaintFormData, ComplaintFirestoreData>(FIREBASE_COLLECTIONS.COMPLAINTS, transformData)
}

/**
 * Custom hook to listen to complaints in real-time and update the query cache.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * complaints in the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToComplaints();
 */
export const useListenToComplaints = () => {
  const constraints = [orderCreatedAt('desc')]
  return useListenToFirestoreQuery<Complaint>(QueryKeys.COMPLAINT_REQUESTS, FIREBASE_COLLECTIONS.COMPLAINTS, constraints)
}

/**
 * Custom hook to fetch complaints and update the query cache.
 *
 * This hook utilizes the `useQuery` hook to fetch complaints
 * from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: complaints, isLoading, error } = useComplaints();
 */
export const useComplaints = () => {
  const constraints = [orderCreatedAt('desc')]
  return useQuery({
    queryKey: QueryKeys.COMPLAINT_REQUESTS,
    queryFn: async () => await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.COMPLAINTS, constraints),
  })
}
