import React, { useEffect, useState, useRef } from 'react'
import { Box, Typography, SxProps } from '@mui/material'
import { Controller, Control, ControllerRenderProps, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UploadedFiles from './UplaodedFIles'
import HelperText from './HelperText'
import DropZoneAreaComponent from './DropZoneArea'
interface FileUploaderProps {
  name: string
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  sx?: SxProps
  helperText?: string
}

const FileUploader: React.FC<FileUploaderProps> = ({ name, label, control, helperText, sx }) => {
  const { t } = useTranslation()

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const uploadedFilesRef = useRef<File[]>(uploadedFiles)

  /**
   * Watches the value of the specified field in the form.
   * This hook subscribes to the form state and re-renders the component when the field value changes.
   */
  const fieldValue = useWatch({ control, name })

  useEffect(() => {
    if (!fieldValue || fieldValue.length === 0) {
      setUploadedFiles([])
    }
  }, [fieldValue])

  useEffect(() => {
    uploadedFilesRef.current = uploadedFiles
  }, [uploadedFiles])

  const handleDrop = (acceptedFiles: File[]) => {
    const newFiles = [...uploadedFilesRef.current, ...acceptedFiles]
    setUploadedFiles(newFiles)
  }

  const handleDeleteFile = (fileToDelete: File, field: ControllerRenderProps) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file !== fileToDelete)
      field.onChange(updatedFiles)
      return updatedFiles
    })
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field, fieldState: { error } }) => (
        <Box sx={sx}>
          <Typography variant="h6">{label}</Typography>

          {/*-------------------- Dropzone Area -------------------- */}
          <DropZoneAreaComponent error={error} uploadedFilesRef={uploadedFilesRef} field={field} handleDrop={handleDrop} t={t} />

          {/*-------------------- Helper Text -------------------- */}
          <HelperText helperText={helperText} error={error} />

          {/*-------------------- Uploaded Files -------------------- */}
          <UploadedFiles uploadedFiles={uploadedFiles} t={t} handleDeleteFile={handleDeleteFile} field={field} />
        </Box>
      )}
    />
  )
}

export default FileUploader
