import React from 'react'
import { AppBar } from '@mui/material'
import { HeaderContainer } from './Header.styled'
import NavigationMenu from './NavigationMenu/NavigationMenu'
import HeaderLogo from './HeaderLogo/HeaderLogo'
import UserSection from './UserSection/UserSection'
import SideBarMenu from './SideBarMenu/SideBarMenu'
import withCapabilities from '../../hoc/withCapabilities'
import { MenuItem } from '../../types/menuItem.types'

const Header: React.FC<{ menuItems: MenuItem[] }> = ({ menuItems }) => {
  return (
    <AppBar position="sticky" color="secondary">
      <HeaderContainer>
        {/*------------ Left Side - Logo ------------*/}
        <HeaderLogo />

        {/*------------ Middle Section - Menu ------------*/}
        <NavigationMenu items={menuItems} desktopOnly />

        {/*------------ Right Side - Icon and Username ------------*/}
        <UserSection desktopOnly />
        {/*On mobile them menu is show in the right side and the logo on the left side*/}
        <SideBarMenu menuItems={menuItems} openFrom="right" mobileAndTabletOnly />
      </HeaderContainer>
    </AppBar>
  )
}

export default withCapabilities(Header)
