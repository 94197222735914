import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Import translations
import en from './en.json'
import gr from './gr.json'

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: en },
      gr: { translation: gr },
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if translation is not found
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  })

export default i18n
