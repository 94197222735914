import { Timestamp } from 'firebase/firestore'
import { User } from 'firebase/auth'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appendWithUserId = (data: any, user: User) => {
  return {
    ...data,
    userId: user?.uid,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appendWithCreatedAt = (data: any) => {
  return {
    ...data,
    createdAt: Timestamp.now(),
  }
}
