import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from './firebase.service'
import { PurchaseRequestFormData } from '../types/purchase.types'
import { UsefulFileFormData } from '../types/usefulFiles.types'
import { UploadedFile } from '../types/storage.types'

export const uploadAttachments = async (files: File[]): Promise<UploadedFile[]> => {
  return await uploadFiles('purchaseAttachments', files)
}

export const uploadFiles = async (folder: string, files: File[]): Promise<UploadedFile[]> => {
  // Create an array of promises for each file upload
  const uploadPromises = files.map(async (file) => {
    const uniqueFileName = createUniqueFileName(file)
    // Create a reference to the file in Firebase Storage
    const fileRef = ref(storage, `${folder}/${uniqueFileName}`)
    // Upload the file
    await uploadBytes(fileRef, file)
    // Get the download URL
    const downloadURL = await getDownloadURL(fileRef)
    return { name: file.name, url: downloadURL, size: file.size, type: file.type }
  })

  // Wait for all uploads to complete
  return await Promise.all(uploadPromises)
}

export const appendWithAttachmentsMeta = (data: PurchaseRequestFormData, attachments: UploadedFile[]) => {
  return { ...data, attachments: attachments as UploadedFile[] }
}

export const appendWithFilesMeta = (data: UsefulFileFormData, files: UploadedFile[]) => {
  return { ...data, files: files as UploadedFile[] }
}

const createUniqueFileName = (file: File) => {
  const fileExtension = file.name.split('.').pop()
  const fileNameWithoutExtension = file.name.replace(`.${fileExtension}`, '')
  const uniqueFileName = `${fileNameWithoutExtension}-${Date.now()}.${fileExtension}`
  return uniqueFileName
}
