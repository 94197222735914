import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { TextField, TextFieldProps, InputAdornment } from '@mui/material'
import withCapabilities from '../../hoc/withCapabilities'

/**
 * TextInput Component
 *
 * A customizable text input component that integrates with react-hook-form for form control.
 *
 * Available input types:
 * - 'text' (default): Standard text input
 * - 'password': Password input with masked characters
 * - 'multiline': Multi-line text input (textarea)
 * - 'price': Number input with Euro (€) symbol
 *
 * Props:
 * - name: string (required) - The name of the form field
 * - control: Control<any> (required) - The react-hook-form control object
 * - type: 'password' | 'multiline' | 'price' (optional) - Specifies the input type
 * - helperText: string (optional) - Helper text to display below the input
 * - ...other TextField props
 *
 * Usage:
 * <TextInput
 *   name="email"
 *   control={control}
 *   label="Email"
 *   type="text"
 *   helperText="Enter your email address"
 * />
 */

interface ControlledTextInputProps extends Omit<TextFieldProps, 'name' | 'type'> {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  type?: 'password' | 'multiline' | 'price' | 'number'
}
const TextInput: React.FC<ControlledTextInputProps> = ({ name, control, type, helperText, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue="" // Ensures the input is always controlled
      render={({ field, fieldState: { error } }) => {
        const textFieldProps: TextFieldProps = {
          ...field,
          variant: 'outlined',
          fullWidth: true,
          error: !!error,
          helperText: error ? error.message : helperText || ' ', // Preserve space for the helper text or error message
          ...props,
        }

        switch (type) {
          case 'password':
            textFieldProps.type = 'password'
            break
          case 'multiline':
            textFieldProps.multiline = true
            textFieldProps.rows = 4
            break
          case 'number':
            textFieldProps.type = 'number'
            break
          case 'price': //TODO read this https://mui.com/material-ui/react-text-field/#type-quot-number-quot
            textFieldProps.type = 'number'
            textFieldProps.slotProps = {
              input: {
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                ...props.slotProps?.input,
              },
              ...props.slotProps,
            }
            break
        }

        return <TextField {...textFieldProps} />
      }}
    />
  )
}

export default withCapabilities(TextInput)
