import React from 'react'
import HomePage from '../pages/HomePage/HomePage'
import LoginPage from '../pages/LoginPage/LoginPage'
import ComplaintPage from '../pages/ComplaintPage/ComplaintPage'
import ComplaintsListPage from '../pages/ComplaintsListPage/ComplaintsListPage'
import { MenuItem } from '../types/menuItem.types'
import { Route } from '../types/route.types'
import TimeOffRequestPage from '../pages/TImeOffRequestPage/TimeOffRequestPage'
import TImeOffApproverPage from '../pages/TimeOffApproverPage/TImeOffApproverPage'
import PurchaseRequestPage from '../pages/PurchaseRequestPage/PurchaseRequestPage'
import PurchaseApproverPage from '../pages/PurchaseApproverPage/PurchaseApproverPage'
import UsefulFilesDownloadPage from '../pages/UsefulFilesDownloadPage/UsefulFilesDownloadPage'
import UsefulFilesUploadPage from '../pages/UsefulFilesUploadPage/UsefulFilesUploadPage'

//---------------------------- EMPLOYEE Routes ----------------------------
export enum EmployeeRoutes {
  HOME = '/employee',
  SUBMIT_COMPLAINT = '/employee/submit-complaint',
  LEAVE_REQUEST = '/employee/leave-request',
  USEFUL_FILES = '/employee/useful-files',
}

//---------------------------- MANAGER Routes ----------------------------
export enum ManagerRoutes {
  HOME = '/manager',
  SUBMIT_COMPLAINT = '/manager/submit-complaint',
  LEAVE_REQUEST = '/manager/leave-request',
  PURCHASE_REQUEST = '/manager/purchase-request',
  USEFUL_FILES = '/manager/useful-files',
}

//---------------------------- ADMIN Routes ----------------------------
export enum AdminRoutes {
  HOME = '/admin',
  COMPLAINTS = '/admin/complaints',
  LEAVE_REQUESTS = '/admin/leave-requests',
  PURCHASE_REQUESTS = '/admin/purchase-requests',
  USEFUL_FILES = '/admin/useful-files',
}

//---------------------------- HR Routes ----------------------------
export enum HRRoutes {
  HOME = '/hr',
  COMPLAINTS = '/hr/complaints',
  LEAVE_REQUESTS = '/hr/leave-requests',
  USEFUL_FILES = '/hr/useful-files',
}

//---------------------------- GM Routes ----------------------------
export enum GMRoutes {
  HOME = '/gm',
  COMPLAINTS = '/gm/complaints',
  LEAVE_REQUESTS = '/gm/leave-requests',
  PURCHASE_REQUESTS = '/gm/purchase-requests',
  USEFUL_FILES = '/gm/useful-files',
}

//---------------------------- CFO Routes ----------------------------
export enum CFORoutes {
  HOME = '/cfo',
  LEAVE_REQUESTS = '/cfo/leave-requests',
  PURCHASE_REQUESTS = '/cfo/purchase-requests',
  USEFUL_FILES = '/cfo/useful-files',
}

//---------------------------- CEO Routes ----------------------------
export enum CEORoutes {
  HOME = '/ceo',
  COMPLAINTS = '/ceo/complaints',
  LEAVE_REQUESTS = '/ceo/leave-requests',
  PURCHASE_REQUESTS = '/ceo/purchase-requests',
  USEFUL_FILES = '/ceo/useful-files',
}

//---------------------------- PUBLIC Routes ----------------------------
export enum PublicRoutes {
  LOGIN = '/login',
}

export const publicRoutes: Route[] = [{ path: PublicRoutes.LOGIN, element: <LoginPage /> }]

//---------------------------- EMPLOYEE Protected Routes ----------------------------
export const protectedEmployeeRoutes: Route[] = [
  { path: EmployeeRoutes.HOME, element: <HomePage /> },
  { path: EmployeeRoutes.SUBMIT_COMPLAINT, element: <ComplaintPage /> },
  { path: EmployeeRoutes.LEAVE_REQUEST, element: <TimeOffRequestPage /> },
  { path: EmployeeRoutes.USEFUL_FILES, element: <UsefulFilesDownloadPage /> },
]

//---------------------------- MANAGER Protected Routes ----------------------------
export const protectedManagerRoutes: Route[] = [
  { path: ManagerRoutes.HOME, element: <HomePage /> },
  { path: ManagerRoutes.SUBMIT_COMPLAINT, element: <ComplaintPage /> },
  { path: ManagerRoutes.LEAVE_REQUEST, element: <TimeOffRequestPage /> },
  { path: ManagerRoutes.PURCHASE_REQUEST, element: <PurchaseRequestPage /> },
  { path: ManagerRoutes.USEFUL_FILES, element: <UsefulFilesDownloadPage /> },
]

//---------------------------- HR Protected Routes ----------------------------
export const protectedHRRoutes: Route[] = [
  { path: HRRoutes.HOME, element: <HomePage /> },
  { path: HRRoutes.COMPLAINTS, element: <ComplaintsListPage /> },
  { path: HRRoutes.LEAVE_REQUESTS, element: <TImeOffApproverPage /> },
  { path: HRRoutes.USEFUL_FILES, element: <UsefulFilesUploadPage /> },
]

//---------------------------- GM Protected Routes ----------------------------
export const protectedGMRoutes: Route[] = [
  { path: GMRoutes.HOME, element: <HomePage /> },
  { path: GMRoutes.COMPLAINTS, element: <ComplaintsListPage /> },
  { path: GMRoutes.LEAVE_REQUESTS, element: <TImeOffApproverPage /> },
  { path: GMRoutes.PURCHASE_REQUESTS, element: <PurchaseApproverPage /> },
  { path: GMRoutes.USEFUL_FILES, element: <UsefulFilesUploadPage /> },
]

//---------------------------- CFO Protected Routes ----------------------------
export const protectedCFORoutes: Route[] = [
  { path: CFORoutes.HOME, element: <HomePage /> },
  { path: CFORoutes.LEAVE_REQUESTS, element: <TImeOffApproverPage /> },
  { path: CFORoutes.PURCHASE_REQUESTS, element: <PurchaseApproverPage /> },
  { path: CFORoutes.USEFUL_FILES, element: <UsefulFilesUploadPage /> },
]

//---------------------------- CEO Protected Routes ----------------------------
export const protectedCEORoutes: Route[] = [
  { path: CEORoutes.HOME, element: <HomePage /> },
  { path: CEORoutes.COMPLAINTS, element: <ComplaintsListPage /> },
  { path: CEORoutes.LEAVE_REQUESTS, element: <TImeOffApproverPage /> },
  { path: CEORoutes.PURCHASE_REQUESTS, element: <PurchaseApproverPage /> },
  { path: CEORoutes.USEFUL_FILES, element: <UsefulFilesUploadPage /> },
]

//---------------------------- ADMIN Protected Routes ----------------------------
export const protectedAdminRoutes: Route[] = [
  { path: AdminRoutes.HOME, element: <HomePage /> },
  { path: AdminRoutes.COMPLAINTS, element: <ComplaintsListPage /> },
  { path: AdminRoutes.LEAVE_REQUESTS, element: <TImeOffApproverPage /> },
  { path: AdminRoutes.PURCHASE_REQUESTS, element: <PurchaseApproverPage /> },
  { path: AdminRoutes.USEFUL_FILES, element: <UsefulFilesUploadPage /> },
]

//---------------------------- EMPLOYEE Menu Items ----------------------------
export const employeeMenuItems: MenuItem[] = [
  { label: 'Home', url: EmployeeRoutes.HOME },
  { label: 'Time Off Request', url: EmployeeRoutes.LEAVE_REQUEST },
  { label: 'File a Complaint', url: EmployeeRoutes.SUBMIT_COMPLAINT },
  { label: 'Useful Files', url: EmployeeRoutes.USEFUL_FILES },
]

//---------------------------- MANAGER Menu Items ----------------------------
export const managerMenuItems: MenuItem[] = [
  { label: 'Home', url: ManagerRoutes.HOME },
  { label: 'Time Off Request', url: ManagerRoutes.LEAVE_REQUEST },
  { label: 'File a Complaint', url: ManagerRoutes.SUBMIT_COMPLAINT },
  { label: 'Purchase Request', url: ManagerRoutes.PURCHASE_REQUEST },
  { label: 'Useful Files', url: ManagerRoutes.USEFUL_FILES },
]

//---------------------------- HR Menu Items ----------------------------
export const hrMenuItems: MenuItem[] = [
  { label: 'Home', url: HRRoutes.HOME },
  { label: 'Time Off Requests', url: HRRoutes.LEAVE_REQUESTS },
  { label: 'Complaints', url: HRRoutes.COMPLAINTS },
  { label: 'Useful Files', url: HRRoutes.USEFUL_FILES },
]

//---------------------------- GM Menu Items ----------------------------
export const gmMenuItems: MenuItem[] = [
  { label: 'Home', url: GMRoutes.HOME },
  { label: 'Time Off Requests', url: GMRoutes.LEAVE_REQUESTS },
  { label: 'Complaints', url: GMRoutes.COMPLAINTS },
  { label: 'Purchase Requests', url: GMRoutes.PURCHASE_REQUESTS },
  { label: 'Useful Files', url: GMRoutes.USEFUL_FILES },
]

//---------------------------- CFO Menu Items ----------------------------
export const cfoMenuItems: MenuItem[] = [
  { label: 'Home', url: CFORoutes.HOME },
  { label: 'Time Off Requests', url: CFORoutes.LEAVE_REQUESTS },
  { label: 'Purchase Requests', url: CFORoutes.PURCHASE_REQUESTS },
  { label: 'Useful Files', url: CFORoutes.USEFUL_FILES },
]

//---------------------------- CEO Menu Items ----------------------------
export const ceoMenuItems: MenuItem[] = [
  { label: 'Home', url: CEORoutes.HOME },
  { label: 'Time Off Requests', url: CEORoutes.LEAVE_REQUESTS },
  { label: 'Complaints', url: CEORoutes.COMPLAINTS },
  { label: 'Purchase Requests', url: CEORoutes.PURCHASE_REQUESTS },
  { label: 'Useful Files', url: CEORoutes.USEFUL_FILES },
]

//---------------------------- ADMIN Menu Items ----------------------------
export const adminMenuItems: MenuItem[] = [
  { label: 'Home', url: AdminRoutes.HOME },
  { label: 'Time Off Requests', url: AdminRoutes.LEAVE_REQUESTS },
  { label: 'Purchase Requests', url: AdminRoutes.PURCHASE_REQUESTS },
  { label: 'Complaints', url: AdminRoutes.COMPLAINTS },
  { label: 'Useful Files', url: AdminRoutes.USEFUL_FILES },
]
