import React from 'react'
import { Navigate } from 'react-router-dom'
import { userAtom } from '../stores/userAtom'
import { useAtom } from 'jotai'
import { useAuth } from '../hooks/useAuth'
import { LinearProgress } from '@mui/material'
import { AuthorityGroup } from '../types/authorityGroup.types'

interface ProtectedRouteProps {
  children: React.ReactElement
  allowedRoles: AuthorityGroup[]
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles }) => {
  const { loading } = useAuth()
  const [user] = useAtom(userAtom)

  if (loading) {
    return <LinearProgress sx={{ height: '5px' }} />
  }

  if (!user) {
    return <Navigate to="/login" />
  }

  if (!allowedRoles.includes(user.authorityGroup as AuthorityGroup)) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}

export default ProtectedRoute
