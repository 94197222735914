import { getDocs, query, where } from 'firebase/firestore'
import { collection } from 'firebase/firestore'
import { AuthorityGroup } from '../types/authorityGroup.types'
import { db } from './firebase.service'
import { FIREBASE_COLLECTIONS } from '../constants/firebase.constants'
import { UserDetails } from '../types/userDetails.types'

export const getUsersByAuthorityGroups = async (authorityGroups: AuthorityGroup[]): Promise<UserDetails[]> => {
  const usersCollection = collection(db, FIREBASE_COLLECTIONS.USERS)
  const usersQuery = query(usersCollection, where('authorityGroup', 'in', authorityGroups))
  const usersSnapshot = await getDocs(usersQuery)
  return usersSnapshot.docs.map((userDoc) => ({
    id: userDoc.id,
    ...(userDoc.data() as UserDetails),
  }))
}

/**
 * Get users by their ids.
 * @param userIds - An array of user ids.
 * @returns A map of user ids to user details.
 */
export const getUsersByIds = async (userIds: string[]): Promise<{ [key: string]: UserDetails }> => {
  const usersCollection = collection(db, FIREBASE_COLLECTIONS.USERS)
  const usersQuery = query(usersCollection, where('__name__', 'in', userIds))
  const usersSnapshot = await getDocs(usersQuery)
  const userMap: { [key: string]: UserDetails } = {}
  usersSnapshot.docs.forEach((userDoc) => {
    userMap[userDoc.id] = userDoc.data() as UserDetails
  })
  return userMap
}
