import React from 'react'
import theme from '../../theme'
import NoDataPage, { NoDataPageProps } from './NoDataPage'

const NoDataTable: React.FC<NoDataPageProps> = (props) => {
  return (
    <NoDataPage
      {...props}
      style={{
        backgroundColor: theme.palette.custom.lightBackground,
        width: '100%',
        borderRadius: '5px',
        padding: '5% 10%',
        ...props.style,
      }}
    />
  )
}

export default NoDataTable
