import { atom } from 'jotai'

const atomMap: Record<string, ReturnType<typeof atom<boolean>>> = {}

export const createSkipNextRealtimeAtom = (collectionName: string) => {
  if (!atomMap[collectionName]) {
    // Create a new atom for this collection name if it doesn't exist in the map
    atomMap[collectionName] = atom(true)
  }
  // Return the atom for the specified collection name
  return atomMap[collectionName]
}
