import React from 'react'
import { IconButton, Collapse, Typography } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import RowDivider from './RowDivider'
import { Cell, Row, IconContainer, ExpandableContentContainer, iconWidth } from './ExpandableTable.styled'
import { cellType, rowLength } from './table.utils'

interface CollapsibleTableRowProps {
  rowId: string
  columns: cellType[]
  expandableContent?: React.ReactNode
  isOpen: boolean
  onToggle: (rowId: string) => void
}

/**
 * Renders the content of a table cell.
 * If the cell data is an object, it will render a cell with a colspan.
 * If the cell data is a valid React element, it will render the element inside a cell.
 * Otherwise, it will render the cell data as a Typography component.
 *
 * @param {cellType} cellData - The data to be rendered in the cell.
 * @param {number} index - The index of the cell.
 * @returns {React.ReactNode} The rendered cell content.
 */
const renderRow = (columns: cellType[]) => {
  return columns.map((data, index) => {
    if (typeof data === 'object' && data !== null && 'data' in data) {
      return (
        <Cell key={index} colSpan={data?.colSpan || 1}>
          {data.data}
        </Cell>
      )
    } else if (React.isValidElement(data)) {
      return <Cell key={index}>{data}</Cell>
    } else {
      return (
        <Cell key={index}>
          <Typography variant="body2">{data}</Typography>
        </Cell>
      )
    }
  })
}

const CollapsibleTableRow: React.FC<CollapsibleTableRowProps> = ({ rowId, columns, expandableContent, isOpen, onToggle }) => {
  return (
    <>
      {/* ---------------- Main Data Row ---------------- */}
      <Row key={rowId}>
        {/* ---------------- Icon ---------------- */}
        {expandableContent && (
          <Cell width={iconWidth}>
            <IconContainer>
              <IconButton aria-label="expand row" size="small" onClick={() => onToggle(rowId)}>
                {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </IconContainer>
          </Cell>
        )}
        {/* ---------------- Cells with data ---------------- */}
        {renderRow(columns)}
      </Row>

      {/* ---------------------- Divider -------------------- */}
      <RowDivider colSpan={expandableContent ? rowLength(columns) + 1 : rowLength(columns)} />

      {/* ---------------- Expandable Content Row ---------------- */}
      {expandableContent && (
        <Row key={rowId + 'expandable'}>
          <Cell colSpan={rowLength(columns) + 1} style={{ padding: 0 }}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <ExpandableContentContainer className={'expandable-content'}>{expandableContent}</ExpandableContentContainer>
            </Collapse>
          </Cell>
        </Row>
      )}
    </>
  )
}

export default CollapsibleTableRow
