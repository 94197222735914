import React from 'react'
import { Typography, Box } from '@mui/material'
import { userAtom } from '../../stores/userAtom'
import { useAtom } from 'jotai'

const HomePage: React.FC = () => {
  const [user] = useAtom(userAtom)

  return (
    <Box sx={{ height: '85vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Welcome, {user?.name}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '16px' }}>
        This is a placeholder for the home page.
      </Typography>
    </Box>
  )
}

export default HomePage
