import { Timestamp } from 'firebase/firestore'
import { UserDetails } from './userDetails.types'
import { BaseStatus } from './base.types'
import { ApprovalFirestoreData } from './approval.types'

// Complaint represents the fully constructed data used in the UI, which includes Firestore data and additional details
export type TimeOffRequest = TimeOffRequestFirestoreData & {
  user: UserDetails | null // Full user details object to include in the complaint
}

// TimeOffRequestFirestoreData represents the data stored in Firestore, which includes TimeOffRequestFormData
export type TimeOffRequestFirestoreData = TimeOffRequestFormData & {
  id: string // Unique identifier for the complaint, fetched from Firestore
  createdAt: Timestamp // Date of submission
  userId: string // Reference to the user who submitted the complaint
  status: TimeOffStatusType
  approvals: ApprovalFirestoreData
}

// TimeOffRequestFormData represents the form data submitted by the user
export type TimeOffRequestFormData = {
  startDate: Timestamp
  endDate: Timestamp
  type: string
  amount: number
  reason?: string
}

export const TimeOffStatus = {
  ...BaseStatus,
} as const

export type TimeOffStatusType = (typeof TimeOffStatus)[keyof typeof TimeOffStatus]
