import React from 'react'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import TableSkeletonLoader from '../common/TableSkeleton'
import NoDataTable from '../common/NoDataTable'
import ErrorTable from '../common/ErrorTable'
import { useListenToUsefulFiles, useUsefulFiles } from '../../hooks/useUsefulFile'
import { UsefulFile } from '../../types/usefulFiles.types'
import ViewDownloadSection from './ViewDownloadSection'

const UsefulFilesList: React.FC = () => {
  const { t } = useTranslation()

  // Start the real-time listener
  useListenToUsefulFiles()

  // Get the list of past purchase requests
  const { data: usefulFiles, isLoading, error } = useUsefulFiles()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error(error)
    return (
      <ErrorTable
        title={t('usefulFiles.viewerPage.error.title')}
        description={t('usefulFiles.viewerPage.error.description')}
        message={t('usefulFiles.viewerPage.error.message')}
      />
    )
  }

  if (!usefulFiles || usefulFiles.length === 0) {
    return (
      <NoDataTable
        title={t('usefulFiles.viewerPage.noFiles.title')}
        description={t('usefulFiles.viewerPage.noFiles.description')}
        message={t('usefulFiles.viewerPage.noFiles.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        t('usefulFiles.viewerPage.header.name'),
        t('usefulFiles.viewerPage.header.uploadedDate'),
        t('usefulFiles.viewerPage.header.uploadedBy'), // uploaded by
        '', // empty column
        '', //  Actions
      ]}
      data={usefulFiles}
      getRowId={(usefulFile: UsefulFile) => usefulFile.id}
      renderRow={(usefulFile: UsefulFile) => [
        usefulFile.name, // name
        timestampToDate(usefulFile.createdAt), // created at
        usefulFile.user?.name, // uploaded by
        '', // empty column
        <ViewDownloadSection fileUrl={usefulFile.url} key={usefulFile.id} />, // actions
      ]}
    />
  )
}

export default UsefulFilesList
