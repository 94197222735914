export enum CostTypeOptions {
  WHOLESALE_PRODUCTS_SUPPLY = 'wholesaleProductsSupply',
  RETAIL_PRODUCTS_SUPPLY = 'retailProductsSupply',
  COMPANY_OPERATIONS_EQUIPMENT = 'companyOperationsEquipment',
  VENUE_EQUIPMENT = 'venueEquipment',
  MARKETING_MATERIALS = 'marketingMaterials',
  MARKETING_SERVICES = 'marketingServices',
  COMPANY_ASSETS = 'companyAssets',
  SPONSORSHIPS = 'sponsorships',
}
