import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import withCapabilities from '../../hoc/withCapabilities'
import { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb'

/**
 * DateInput Component
 *
 * A reusable date picker component that integrates with react-hook-form for form control.
 *
 * Props:
 * - name: string (required) - The name of the form field
 * - control: Control<any> (required) - The react-hook-form control object
 * - helperText: string (optional) - Helper text to display below the input
 * - ...other DatePicker props
 *
 * Usage:
 * <DateInput
 *   name="dateOfIncident"
 *   control={control}
 *   label="Date of Incident"
 *   helperText="Select the date of the incident"
 * />
 */

interface ControlledDateInputProps extends Omit<DatePickerProps<Dayjs>, 'name'> {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  helperText?: string
}

const DateInput: React.FC<ControlledDateInputProps> = ({ name, control, helperText, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null} // Ensures the input is always controlled
      render={({ field, fieldState: { error } }) => {
        const datePickerProps: DatePickerProps<Dayjs> = {
          inputRef: field.ref,
          ...props,
          ...field,
          slotProps: {
            textField: {
              helperText: error ? error.message : helperText || ' ', // Preserve space for the helper text or error message,
              error: !!error,
            },
          },
          format: 'DD/MM/YYYY',
          onChange: (date) => field.onChange(date),
        }

        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
            <DatePicker {...datePickerProps} sx={{ width: '100%' }} />
          </LocalizationProvider>
        )
      }}
    />
  )
}

export default withCapabilities(DateInput)
