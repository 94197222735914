import React from 'react'
import { useIsDesktop, useIsMobile, useIsMobileAndTablet, useIsTabletUp } from '../hooks/useScreenSize'

interface ResponsiveProps {
  mobileOnly?: boolean
  tabletUpOnly?: boolean
  mobileAndTabletOnly?: boolean
  desktopOnly?: boolean
}

/**
 * Higher Order Component (HOC) to conditionally render a component based on the current viewport size.
 *
 * This HOC provides responsive visibility capabilities to the wrapped component. It uses custom hooks
 * to determine the current screen size and conditionally renders the component based on the provided
 * props.
 *
 * @param {React.ComponentType<P>} Component - The component to be wrapped and enhanced with responsive visibility.
 * @returns {React.FC<Props>} - The wrapped component with responsive visibility capabilities.
 *
 * @template P - The type of the component's props.
 *
 * @example
 * const ResponsiveComponent = withResponsiveVisibility(MyComponent);
 *
 * <ResponsiveComponent mobileOnly />
 * <ResponsiveComponent desktopOnly />
 */
const withResponsiveVisibility = <P extends object>(Component: React.ComponentType<P>) => {
  type Props = JSX.LibraryManagedAttributes<typeof Component, P & ResponsiveProps>

  const WrappedComponent: React.FC<Props> = ({ mobileOnly, tabletUpOnly, mobileAndTabletOnly, desktopOnly, ...props }) => {
    const isMobile = useIsMobile()
    const isTabletUp = useIsTabletUp()
    const isMobileAndTablet = useIsMobileAndTablet()
    const isDesktop = useIsDesktop()

    if (mobileOnly && !isMobile) return null
    if (mobileAndTabletOnly && !isMobileAndTablet) return null
    if (tabletUpOnly && !isTabletUp) return null
    if (desktopOnly && !isDesktop) return null

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Component {...(props as any)} />
  }

  return WrappedComponent
}

export default withResponsiveVisibility
