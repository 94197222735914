// hooks/useScreenSizes.ts

import { useMediaQuery } from '@mui/material'
import { Breakpoint, useTheme } from '@mui/material/styles'

export const useIsMobile = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('tablet'))
}

export const useIsTablet = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.between('tablet', 'laptop'))
}

export const useIsMobileAndTablet = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('laptop'))
}

export const useIsDesktop = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('laptop'))
}

export const useIsTabletUp = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('tablet'))
}

// General-purpose hook to determine if the screen matches a custom breakpoint
export const useBreakpoint = (breakpoint: string | number): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.only(breakpoint as Breakpoint))
}
