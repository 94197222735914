export type headerType = string | { data: string; colSpan?: number }
export type cellType = string | { data: React.ReactNode | string; colSpan?: number } | React.ReactNode

export const rowLength = (columns: cellType[]) => {
  return columns.reduce((acc: number, column: cellType) => {
    if (column && typeof column === 'object' && 'colSpan' in column) {
      return acc + (column?.colSpan || 1)
    }
    return acc + 1
  }, 0)
}
