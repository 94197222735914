import React from 'react'
import ErrorPage, { ErrorPageProps } from './ErrorPage'
import theme from '../../theme'

const ErrorTable: React.FC<ErrorPageProps> = (props) => {
  return (
    <ErrorPage
      {...props}
      style={{
        backgroundColor: theme.palette.custom.lightBackground,
        width: '100%',
        borderRadius: '5px',
        padding: '5% 10%',
        ...props.style,
      }}
    />
  )
}

export default ErrorTable
