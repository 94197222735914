import { z } from 'zod'
import i18next from 'i18next'
import { Dayjs } from 'dayjs'

const t = i18next.t.bind(i18next)

export const loginValidationSchema = z.object({
  email: z
    .string()
    .trim()
    .email({ message: t('login-page.validation.email.invalid') })
    .min(1, { message: t('login-page.validation.email.required') }),
  password: z
    .string()
    .min(1, { message: t('login-page.validation.password.required') })
    .min(6, { message: t('login-page.validation.password.minLength', { count: 6 }) }),
})

//--------------------------------- Complaint Form ---------------------------------
export const complaintValidationSchema = z.object({
  //-------- Date and Time of Incident --------
  incidentDateTime: z.custom<Dayjs>().transform((val, ctx) => {
    if (val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('complaints.form.validation.dateTimeOfIncident.required'),
      })
      return z.NEVER
    }
    return val
  }),

  //-------- Location --------
  location: z.string().optional(),

  //-------- Witnesses --------
  witnesses: z.string().optional(),

  //-------- Incident Description --------
  incidentDescription: z
    .string()
    .min(10)
    .refine((val) => val.trim() !== '', {
      message: t('complaints.form.validation.incidentDescription.required'),
    }),
})

//--------------------------------- Time Off Form ---------------------------------
export const timeOffRequestValidationSchema = z.object({
  //-------- Start Date --------
  startDate: z.custom<Dayjs>().transform((val, ctx) => {
    if (val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('timeOff.form.validation.startDate.required'),
      })
      return z.NEVER
    }
    return val
  }),

  //-------- End Date --------
  endDate: z.custom<Dayjs>().transform((val, ctx) => {
    if (val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('timeOff.form.validation.endDate.required'),
      })
      return z.NEVER
    }
    return val
  }),

  //-------- Type --------
  type: z.string().refine((val) => val.trim() !== '', {
    message: t('timeOff.form.validation.type.required'),
  }),

  //-------- Amount --------
  amount: z.string().transform((val, ctx) => {
    const parsed = parseInt(val)
    if (isNaN(parsed) || parsed === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('timeOff.form.validation.amount.required'),
      })
      return z.NEVER
    }
    return parsed
  }),

  //-------- Reason --------
  reason: z.string().optional(),
})

//--------------------------------- Purchase Form ---------------------------------
export const purchaseRequestValidationSchema = z.object({
  //-------- Subject --------
  subject: z.string().refine((val) => val.trim() !== '', {
    message: t('purchase.form.validation.subject.required'),
  }),

  //-------- Cost Type --------
  costType: z.string().refine((val) => val.trim() !== '', {
    message: t('purchase.form.validation.costType.required'),
  }),

  //-------- Recommended Supplier --------
  recommendedSupplier: z.string().refine((val) => val.trim() !== '', {
    message: t('purchase.form.validation.recommendedSupplier.required'),
  }),

  //-------- Comment --------
  comment: z.string().optional(),

  //-------- Price --------
  price: z.string().transform((val, ctx) => {
    const parsed = parseInt(val)
    if (isNaN(parsed) || parsed === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('purchase.form.validation.price.required'),
      })
      return z.NEVER
    }
    return parsed
  }),

  //-------- Attachments --------
  attachments: z
    .array(z.instanceof(File))
    .transform((val, ctx) => {
      if (val === null || val === undefined || val.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('purchase.form.validation.attachments.required'),
        })
        return z.NEVER
      }
      return val
    })
    .refine((file) => file !== z.NEVER && file.every((f) => (f as File).size < 5 * 1024 * 1024), {
      message: t('purchase.form.validation.attachments.maxSize'),
    })
    .refine((file) => file !== z.NEVER && file.every((f) => checkFileType(f as File)), {
      message: t('purchase.form.validation.attachments.fileType'),
    }),
})

//--------------------------------- Useful Files Form ---------------------------------
export const usefulFilesValidationSchema = z.object({
  //-------- Files --------
  files: z
    .array(z.instanceof(File))
    .transform((val, ctx) => {
      if (val === null || val === undefined || val.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('usefulFiles.form.validation.files.required'),
        })
        return z.NEVER
      }
      return val
    })
    .refine((file) => file !== z.NEVER && file.every((f) => (f as File).size < 5 * 1024 * 1024), {
      message: t('usefulFiles.form.validation.files.maxSize'),
    })
    .refine((file) => file !== z.NEVER && file.every((f) => checkFileType(f as File)), {
      message: t('usefulFiles.form.validation.files.fileType'),
    }),
})

const checkFileType = (file: File) => {
  if (!file?.name) {
    return false
  }

  const allowedFileTypes = ['docx', 'pdf', 'xlsx', 'png', 'jpg', 'jpeg']
  const fileType = file.name.split('.').pop()

  if (!fileType) {
    return false
  }
  return allowedFileTypes.includes(fileType)
}
