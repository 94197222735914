import React from 'react'
import { MenuContainer } from './NavigationMenu.styled'
import { BoxProps, Typography } from '@mui/material'
import withCapabilities from '../../../hoc/withCapabilities'
import { Link } from 'react-router-dom'
import { MenuItem } from '../../../types/menuItem.types'

interface NavigationMenuProps extends BoxProps {
  items: MenuItem[]
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ items, ...props }) => {
  return (
    <MenuContainer {...props}>
      {items.map((item) => (
        <Link key={item.url} to={item.url} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography variant="body1" sx={{ margin: 1 }}>
            {item.label}
          </Typography>
        </Link>
      ))}
    </MenuContainer>
  )
}

export default withCapabilities(NavigationMenu)
