import { AuthorityGroup, AuthorityGroups } from '../types/authorityGroup.types'

export const allAuthorities: AuthorityGroup[] = [
  AuthorityGroups.EMPLOYEE,
  AuthorityGroups.HR,
  AuthorityGroups.CFO,
  AuthorityGroups.CEO,
  AuthorityGroups.GM,
  AuthorityGroups.MANAGER,
  AuthorityGroups.ADMIN,
]

export const isAuthorized = (authorityGroup: AuthorityGroup) => {
  return allAuthorities.includes(authorityGroup)
}

export const isEmployee = (authorityGroup: AuthorityGroup) => {
  return authorityGroup === AuthorityGroups.EMPLOYEE
}

export const isApprover = (authorityGroup: AuthorityGroup) => {
  return [AuthorityGroups.ADMIN, AuthorityGroups.CEO, AuthorityGroups.GM, AuthorityGroups.HR, AuthorityGroups.CFO].includes(authorityGroup)
}
