import React from 'react'
import { BoxProps, IconButton, Typography } from '@mui/material'
import { CardTravel } from '@mui/icons-material'
import { LogoContainer } from './HeaderLogo.styled'
import { useNavigate } from 'react-router-dom'
import withCapabilities from '../../../hoc/withCapabilities'

const HeaderLogo: React.FC<BoxProps> = (props) => {
  const navigate = useNavigate()

  return (
    <LogoContainer
      {...props}
      onClick={() => {
        navigate('/')
      }}
    >
      <IconButton color="primary" disableRipple>
        <CardTravel />
      </IconButton>
      <Typography variant="h6" noWrap color="primary">
        WE
      </Typography>
    </LogoContainer>
  )
}

export default withCapabilities(HeaderLogo)
