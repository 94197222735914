import compose from 'lodash/fp/compose'
import withResponsiveVisibility from './withResponsiveVisibility'
/**
 * withCapabilities Higher Order Component (HOC)
 *
 * This HOC composes multiple capabilities for a component, enhancing it with
 * additional functionalities. Currently, it applies the following capabilities:
 *
 * 1. Responsive Visibility: Allows the component to be conditionally rendered
 *    based on the current viewport size (e.g., hidden on mobile, visible on desktop).
 *
 * Usage:
 * import withCapabilities from './withCapabilities'
 *
 * const EnhancedComponent = withCapabilities(YourComponent)
 *
 * This will apply all capabilities to YourComponent, making it responsive
 * and potentially adding other features in the future.
 */

const withCapabilities = compose(withResponsiveVisibility)

export default withCapabilities
