import React from 'react'
import AppRoutes from './routes/AppRoutes'
import { AuthProvider } from './contexts/AuthContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastConfig } from './config/toastify.config'

const App: React.FC = () => {
  return (
    <>
      <ToastContainer {...toastConfig} />
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </>
  )
}

export default App
