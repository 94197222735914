import { Slide, ToastPosition } from 'react-toastify'

export const toastConfig = {
  position: 'bottom-right' as ToastPosition,
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'colored',
  transition: Slide,
}
