import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { useTranslation } from 'react-i18next'
import TimeOffForm from '../../features/TimeOff/TimeOffForm'
import TimeOffEmployeePastList from '../../features/TimeOff/TimeOffViewerPastList'

const TimeOffRequestPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageTemplate title={t('timeOff.employeePage.title')} contentWidth="boxed">
        <TimeOffForm />
      </PageTemplate>
      <PageTemplate title={t('timeOff.employeePage.past.title')}>
        <TimeOffEmployeePastList />
      </PageTemplate>
    </>
  )
}

export default withCapabilities(TimeOffRequestPage)
