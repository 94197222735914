import React from 'react'
import { Typography } from '@mui/material'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate, timestampToTime } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import { Complaint } from '../../types/complaint.types'
import TableSkeletonLoader from '../common/TableSkeleton'
import ErrorPage from '../common/ErrorPage'
import NoDataPage from '../common/NoDataPage'
import { useComplaints, useListenToComplaints } from '../../hooks/useComplaint'

const ComplaintList: React.FC = () => {
  const { t } = useTranslation()

  useListenToComplaints()
  const { data: complaints, isLoading, error } = useComplaints()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error('Error fetching complaints:', error)
    return (
      <ErrorPage
        title={t('complaints.list.error.title')}
        description={t('complaints.list.error.description')}
        message={t('complaints.list.error.message')}
      />
    )
  }

  if (!complaints || complaints.length === 0) {
    return (
      <NoDataPage
        title={t('complaints.list.noComplaints.title')}
        description={t('complaints.list.noComplaints.description')}
        message={t('complaints.list.noComplaints.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        t('complaints.list.header.name'),
        t('complaints.list.header.division'),
        t('complaints.list.header.role'),
        t('complaints.list.header.dateOfIncident'),
        t('complaints.list.header.timeOfIncident'),
        t('complaints.list.header.location'),
        t('complaints.list.header.witnesses'),
        t('complaints.list.header.dateFiled'),
      ]}
      data={complaints}
      getRowId={(complaint: Complaint) => complaint.id}
      renderRow={(complaint: Complaint) => [
        complaint.user?.name || '', // Name
        complaint.user?.division || '', // Division
        complaint.user?.role || '', // Role
        timestampToDate(complaint.incidentDateTime), // Date of Incident
        timestampToTime(complaint.incidentDateTime), // Time of Incident
        complaint.location || '', // Location
        complaint.witnesses || '', // Witnesses
        timestampToDate(complaint.createdAt), // Date Filed
      ]}
      renderExpandableContent={(complaint: Complaint) => <Typography sx={{ padding: '30px' }}>{complaint.incidentDescription}</Typography>}
    />
  )
}

export default ComplaintList
