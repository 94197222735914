import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Alert } from '@mui/material'
import { LoginFormContainer, ServerErrorContainer, textInputStyle } from './LoginForm.styled'
import { useNavigate } from 'react-router-dom'
import ActionButton from '../../components/Button/Button'
import TextInput from '../../components/InputFields/TextInput'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/useAuth'
import { loginValidationSchema } from '../../utils/formValidations.utils'

type FormData = z.infer<typeof loginValidationSchema>

const LoginForm: React.FC = () => {
  const { login } = useAuth()
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { control, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(loginValidationSchema),
  })

  const [serverError, setServerError] = useState<string | null>(null)
  const { t } = useTranslation()

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true)

    try {
      setServerError(null) // Clear previous errors
      await login(data.email, data.password)
      setIsSubmitting(false)
      navigate('/')
    } catch (_) {
      setServerError(t('login.invalidCredentials'))
      setIsSubmitting(false)
    }
  }

  return (
    <LoginFormContainer>
      {/*--------- Server Error Message --------- */}
      <ServerErrorContainer>{serverError && <Alert severity="error">{serverError}</Alert>}</ServerErrorContainer>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/*--------- Email Input --------- */}
        <TextInput
          name="email"
          label={t('login.email')}
          helperText={t('login.emailPlaceholder')}
          control={control}
          sx={textInputStyle}
          onFocus={() => setServerError(null)}
        />

        {/*--------- Password Input --------- */}
        <TextInput
          name="password"
          label={t('login.password')}
          helperText={t('login.passwordPlaceholder')}
          control={control}
          type="password"
          sx={textInputStyle}
          onFocus={() => setServerError(null)}
        />

        {/*--------- Login Button --------- */}
        <ActionButton buttonType="primary" type="submit" size="fullWidth" disabled={isSubmitting}>
          Login
        </ActionButton>
      </form>
    </LoginFormContainer>
  )
}

export default LoginForm
