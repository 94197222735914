import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import ActionButton from '../../components/Button/Button'
import { toast } from 'react-toastify'
import withCapabilities from '../../hoc/withCapabilities'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'
import FileUploader from '../../components/FileUploader/FileUploader'
import { StyledForm } from '../../App.styles'
import { GridContainer } from '../../components/Grid/GridContainer'
import { usefulFilesValidationSchema } from '../../utils/formValidations.utils'
import { useUploadUsefulFiles } from '../../hooks/useUsefulFile'

type FormData = z.infer<typeof usefulFilesValidationSchema>

const UsefulFilesUploaderForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: zodResolver(usefulFilesValidationSchema),
  })

  useEffect(() => {
    reset()
  }, [isSubmitSuccessful])

  const { t } = useTranslation()

  const { mutate } = useUploadUsefulFiles()

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true)
    mutate(data, {
      onSuccess: () => {
        toast.success(t('usefulFiles.form.success'))
        reset()
        setIsSubmitting(false)
      },
      onError: () => {
        toast.error(t('usefulFiles.form.error'))
        setIsSubmitting(false)
      },
    })
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        {/*--------------- Files -------------- */}
        <Grid size={{ mobile: 12 }}>
          <FileUploader
            name="files"
            label={t('usefulFiles.form.files.label')}
            helperText={t('usefulFiles.form.files.helperText')}
            control={control}
            sx={{ marginBottom: '16px' }}
          />
        </Grid>
        {/*--------------- Submit Button -------------- */}
        <Grid size={{ mobile: 12 }}>
          <ActionButton buttonType="primary" type="submit" size="fullWidth" disabled={isSubmitting}>
            {t('usefulFiles.form.submit')}
          </ActionButton>
        </Grid>
      </GridContainer>
    </StyledForm>
  )
}

export default withCapabilities(UsefulFilesUploaderForm)
