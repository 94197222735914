import { Box, Divider, IconButton, Typography } from '@mui/material'
import { Download as DownloadIcon } from '@mui/icons-material'
import { PurchaseRequest } from '../../types/purchase.types'

const PurchaseExpandableContent = ({ purchaseRequest }: { purchaseRequest: PurchaseRequest }) => (
  <Box style={{ padding: '30px' }}>
    {/* --------------- Comment --------------- */}
    {purchaseRequest.comment && <Typography>{purchaseRequest.comment}</Typography>}
    {purchaseRequest.comment && <Divider sx={{ marginTop: '10px' }} />}
    {/* --------------- Attachments --------------- */}
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
      {purchaseRequest.attachments?.map((attachment, index) => (
        <Box key={`attachments-${index}`} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <Typography variant="body2">{attachment.name}</Typography>
          <a href={attachment.url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
            <IconButton>
              <DownloadIcon />
            </IconButton>
          </a>
        </Box>
      ))}
    </Box>
  </Box>
)

export default PurchaseExpandableContent
