import React from 'react'
import LoginForm from '../../features/Login/LoginForm'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Image, GridFormContainer, GridPageContainer } from './LoginPage.styled'
import { t } from 'i18next'
import loginIllustration from '../../assets/images/login-screen.png'
import { useIsMobile } from '../../hooks/useScreenSize'

const LoginPage: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <GridPageContainer container spacing={isMobile ? 0 : 8}>
      {/*--------- Left Column - Image --------- */}
      <Grid size={{ mobile: 12, tablet: 6 }}>
        <Image src={loginIllustration} alt="Login Illustration" />
      </Grid>

      {/*--------- Right Column - Login Form --------- */}
      <GridFormContainer size={{ mobile: 12, tablet: 6 }}>
        <Box maxWidth="400px">
          <Typography variant="h5" mb={2}>
            {t('login.welcome')}
          </Typography>
          <LoginForm />
        </Box>
      </GridFormContainer>
    </GridPageContainer>
  )
}

export default LoginPage
