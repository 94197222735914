import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import TextInput from '../../components/InputFields/TextInput'
import ActionButton from '../../components/Button/Button'
import { toast } from 'react-toastify'
import { complaintValidationSchema } from '../../utils/formValidations.utils'
import withCapabilities from '../../hoc/withCapabilities'
import { useTranslation } from 'react-i18next'
import { dateToTimestamp } from '../../utils/dateTime.utils'
import DateTimeInput from '../../components/InputFields/DateTimeInput'
import { useSubmitComplaint } from '../../hooks/useComplaint'
import Grid from '@mui/material/Grid2'
import { StyledForm } from '../../App.styles'
import { GridContainer } from '../../components/Grid/GridContainer'

type FormData = z.infer<typeof complaintValidationSchema>

const ComplaintForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: zodResolver(complaintValidationSchema),
  })

  useEffect(() => {
    reset()
  }, [isSubmitSuccessful])

  const { t } = useTranslation()
  const { mutate } = useSubmitComplaint()

  const onSubmit = async (data: FormData) => {
    // Prepare data to store in Firestore
    const complaintData = {
      ...data,
      incidentDateTime: dateToTimestamp(data.incidentDateTime),
    }

    mutate(
      { data: complaintData },
      {
        onSuccess: () => {
          toast.success(t('complaints.form.success'))
          setIsSubmitting(false)
        },
        onError: () => {
          toast.error(t('complaints.form.error'))
          setIsSubmitting(false)
        },
      }
    )
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        {/*--------------- Date and Time of Incident -------------- */}
        <Grid size={{ mobile: 12, tablet: 6, desktop: 4 }}>
          <DateTimeInput name="incidentDateTime" label={t('complaints.form.dateTimeOfIncident')} control={control} disableFuture />
        </Grid>
        {/*--------------- Location -------------- */}
        <Grid size={{ mobile: 12, tablet: 6, desktop: 4 }}>
          <TextInput name="location" label={t('complaints.form.location')} control={control} helperText={t('complaints.form.locationHelperText')} />
        </Grid>
        {/*--------------- Witnesses -------------- */}
        <Grid size={{ mobile: 12, tablet: 6, desktop: 4 }}>
          <TextInput
            name="witnesses"
            label={t('complaints.form.witnesses')}
            control={control}
            helperText={t('complaints.form.witnessesHelperText')}
          />
        </Grid>
        {/*--------------- Incident Description -------------- */}
        <Grid size={{ mobile: 12 }}>
          <TextInput
            name="incidentDescription"
            label={t('complaints.form.incidentDescription')}
            helperText={t('complaints.form.incidentDescriptionHelperText')}
            control={control}
            multiline
            rows={12}
          />
        </Grid>

        {/*--------------- Submit Button -------------- */}
        <Grid size={12}>
          <ActionButton buttonType="primary" type="submit" size="fullWidth" disabled={isSubmitting}>
            {t('complaints.form.submit')}
          </ActionButton>
        </Grid>
      </GridContainer>
    </StyledForm>
  )
}

export default withCapabilities(ComplaintForm)
