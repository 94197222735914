import { Box, TableCell } from '@mui/material'

import { styled } from '@mui/material'

export const HeaderCell = styled(TableCell)`
  border-color: #6a6a6a2e;
`
export const BodyCell = styled(TableCell)`
  border: none;
`
export const AttachmentContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-right: 10px;
  border-right: 1px dashed #6a6a6a7a;
  &:last-child {
    border-right: none;
    padding-right: 0;
  }
`
