import React from 'react'
import { Route } from 'react-router-dom'
import { Box } from '@mui/material'
import Header from '../components/Header/Header'
import { AuthorityGroup } from '../types/authorityGroup.types'
import ProtectedRoute from '../routes/ProtectedRoute'
import { MenuItem } from '../types/menuItem.types'
import { Route as RouteConfig } from '../types/route.types'

export const generateProtectedRoutes = (routes: RouteConfig[], allowedRoles: AuthorityGroup[], menuItems: MenuItem[]) => {
  return routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        <Box>
          <Header menuItems={menuItems} />
          <ProtectedRoute allowedRoles={allowedRoles}>{route.element as React.ReactElement}</ProtectedRoute>
        </Box>
      }
    />
  ))
}
