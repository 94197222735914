import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PublicRoutes } from '../config/routes.config'

const UnauthorizedPage: React.FC = () => {
  const navigate = useNavigate()

  const handleGoHome = () => {
    navigate(PublicRoutes.LOGIN)
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h1" color="primary" gutterBottom>
        403
      </Typography>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        You are not authorized to access this page
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        Go to Login Page
      </Button>
    </Box>
  )
}

export default UnauthorizedPage
