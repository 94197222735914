import { Box, styled, TableCell, TableRow, Typography } from '@mui/material'

export const borderPadding = '20px'
export const iconWidth = '80px'

export const Row = styled(TableRow)`
  border: none;
  &:last-child .expandable-content {
    margin-bottom: ${borderPadding};
  }
`
export const Cell = styled(TableCell)`
  border: none;
  text-align: center;
`

export const IconContainer = styled(Box)`
  padding-left: 10px;
  display: flex;
  align-items: center;
`
export const ExpandableContentContainer = styled(Box)`
  margin: 0 ${borderPadding};
  background: ${({ theme }) => theme.palette.custom.lightBackground};
`
export const BoldTypography = styled(Typography)`
  font-weight: bold;
`

export const Divider = styled(Box)`
  margin-left: ${borderPadding};
  margin-right: ${borderPadding};
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom.lightBackground};
`
