import { Box, styled } from '@mui/material'

export const LoginFormContainer = styled(Box)`
  align-items: center;
  justify-content: space-between;
`
export const textInputStyle = { marginBottom: '16px' }

export const ServerErrorContainer = styled(Box)`
  min-height: 24px;
  margin-bottom: 16px;
  width: 100%;
`
