import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Use environment variables to load configuration based on environment (dev or prod)
const firebaseProdConfig = {
  apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID,
  recaptchaSiteKey: process.env.REACT_APP_PROD_RECAPTCHA_ENTERPRISE_SITE_KEY,
}

const firebaseDevConfig = {
  apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID,
  recaptchaSiteKey: process.env.REACT_APP_DEV_RECAPTCHA_ENTERPRISE_SITE_KEY,
}

const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? firebaseProdConfig : firebaseDevConfig

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// When running locally, we need to set the debug token for App Check
// For deployment on dev environment we dont need the debug token
if (process.env.REACT_APP_ENV === 'development') {
  if (!process.env.REACT_APP_DISABLE_APPCHECK_DEBUG_TOKEN) {
    // @ts-expect-error: Setting debug token for App Check in development
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_DEV_FIREBASE_APPCHECK_DEBUG_TOKEN
  }
}

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(firebaseConfig.recaptchaSiteKey || ''),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
})

export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
