import { FirestoreData } from '../types/firestore.types'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { BaseStatusType } from '../types/base.types'
import { AuthorityGroup } from '../types/authorityGroup.types'
import { calculateRequestStatus } from './approval.service'
import { ApprovalFirestoreData } from '../types/approval.types'

/**
 * Custom hook to update the cached query data for a specific query key in the React Query cache.
 *
 * This hook is useful for optimistically updating the UI with new data
 * without having to refetch from the server. It finds the item with the specified
 * ID in the cached data and merges it with the provided new data.
 *
 * @template T - The type of the data being updated, extending FirestoreDataType.
 * @returns {Function} A function that can be called to update the query data.
 * @param {string} id - The unique identifier of the item to update.
 * @param {QueryKeys} queryKey - The key of the query whose data should be updated.
 * @param {Partial<T>} newData - The new data to merge with the existing item data.
 *
 * @example
 * const updateQueryData = useUpdateQueryData<TimeOffRequest>();
 * updateQueryData('12345', QueryKeys.PENDING_TIME_OFF_REQUESTS, { status: 'APPROVED' });
 */

export const useUpdateQueryData = <T extends FirestoreData>() => {
  const queryClient = useQueryClient()

  return (id: string, queryKey: QueryKey, newData: Partial<T>) => {
    queryClient.setQueryData([queryKey], (oldData: T[] | undefined) => {
      if (!oldData) {
        return []
      }
      return oldData.map((request) => (request.id === id ? { ...request, ...newData } : request))
    })
  }
}

export const approvalsAndStatusCacheUpdater =
  <T extends FirestoreData & { approvals: ApprovalFirestoreData; status: BaseStatusType }>(authorityGroup: AuthorityGroup, status: BaseStatusType) =>
  (oldData: T[], data: T): T[] => {
    const approvals = { ...data.approvals, [authorityGroup]: status }
    const updatedStatus = calculateRequestStatus(approvals)

    const updatedData = {
      ...data,
      approvals,
      status: updatedStatus,
    }
    return [...(oldData || []), updatedData]
  }

export const importAndUpdateDataCacheUpdater = <T extends FirestoreData>(oldData: T[] = [], incomingDocuments: T[]): T[] => {
  const updatedData = [...oldData]

  incomingDocuments.forEach((incomingDoc) => {
    const existingDocIndex = updatedData.findIndex((doc) => doc.id === incomingDoc.id)

    if (existingDocIndex !== -1) {
      // Update the existing document
      updatedData[existingDocIndex] = incomingDoc
    } else {
      // Add the new document
      updatedData.push(incomingDoc)
    }
  })

  return updatedData
}
