import { FIREBASE_COLLECTIONS } from '../constants/firebase.constants'
import { QueryKeys } from '../constants/queryKeys.constants'
import { useListenToFirestoreQuery, useUpdateDocument } from './useFirestore'
import { appendWithUserId } from '../utils/form.utils'
import { userAtom } from '../stores/userAtom'
import { useAtom } from 'jotai'
import { useQuery, useMutation } from '@tanstack/react-query'
import { getDocsAndEnhanceWithUserDetails, orderCreatedAt, whereStatusActive, writeMultipleDocuments } from '../services/firestore.service'
import { uploadFiles } from '../services/storage.service'
import { UsefulFileFirestoreData, UsefulFileFormData, UsefulFileStatus } from '../types/usefulFiles.types'
import { addDataUpdater } from '../utils/query.utils'

/**
 * Custom hook to upload useful files to Firestore.
 *
 * This hook uses the `useWriteDocument` hook to handle the submission
 * of useful files to the Firestore database. It automatically sets the
 * status of the files to `ACTIVE` upon submission.
 *
 * @returns {Object} An object containing the mutation function to upload useful files.
 *
 * @example
 * const uploadUsefulFiles = useUploadUsefulFiles();
 *
 * uploadUsefulFiles.mutate(
 *     { data: usefulFilesData },
 *     { onSuccess: () => {}, onError: () => {} }
 * )
 */
export const useUploadUsefulFiles = () => {
  const [user] = useAtom(userAtom)

  if (!user) {
    throw new Error('User not found')
  }

  const transformData = async (data: UsefulFileFormData): Promise<UsefulFileFirestoreData[]> => {
    const files = await uploadFiles('usefulFiles', data.files)
    const dataWithUserId = appendWithUserId({}, user)
    return files.map((file) => ({
      ...dataWithUserId,
      status: UsefulFileStatus.ACTIVE,
      name: file.name,
      url: file.url,
      size: file.size,
      type: file.type,
      createdAt: new Date(),
    })) as UsefulFileFirestoreData[]
  }

  return useMutation({
    mutationFn: async (data: UsefulFileFormData) => {
      const transformedData = await transformData(data)
      await writeMultipleDocuments(transformedData, FIREBASE_COLLECTIONS.USEFUL_FILES)
    },
  })
}

/**
 * Custom hook to listen to active useful files in real-time.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * useful files with a status of `ACTIVE` in the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToUsefulFiles();
 */
export const useListenToUsefulFiles = () => {
  const constraints = [whereStatusActive(), orderCreatedAt('asc')]
  return useListenToFirestoreQuery<UsefulFileFirestoreData>(QueryKeys.ACTIVE_USEFUL_FILES, FIREBASE_COLLECTIONS.USEFUL_FILES, constraints)
}

/**
 * Custom hook to fetch active useful files from Firestore.
 *
 * This hook utilizes the `useFirestoreQuery` hook to fetch useful files
 * from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: usefulFiles, isLoading, error } = useUsefulFiles();
 */
export const useUsefulFiles = () => {
  const constraints = [whereStatusActive(), orderCreatedAt('asc')]

  return useQuery({
    queryKey: QueryKeys.ACTIVE_USEFUL_FILES,
    queryFn: async () => {
      const snapshot = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.USEFUL_FILES, constraints)
      return snapshot
    },
  })
}

/**
 * Custom hook to delete a useful file by updating its status to `DELETED`.
 *
 * This hook utilizes the `useUpdateDocument` hook to update the status of a
 * useful file to `DELETED` in the Firestore database.
 *
 * @returns {Object} An object containing the mutation function to delete a useful file.
 *
 * @example
 * const deleteUsefulFile = useDeleteUsefulFile();
 *
 * deleteUsefulFile.mutate(
 *     fileId,
 *     { onSuccess: () => {}, onError: () => {} }
 * )
 */
export const useDeleteUsefulFile = () => {
  const firebaseDataUpdater = () => ({ status: UsefulFileStatus.DELETED })

  return useUpdateDocument<UsefulFileFirestoreData>(
    FIREBASE_COLLECTIONS.USEFUL_FILES,
    firebaseDataUpdater,
    QueryKeys.ACTIVE_USEFUL_FILES,
    QueryKeys.DELETED_USEFUL_FILES,
    addDataUpdater
  )
}
