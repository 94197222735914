import React from 'react'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import TableSkeletonLoader from '../common/TableSkeleton'
import ApproversStatus from '../common/ApproversStatus'
import NoDataTable from '../common/NoDataTable'
import ErrorTable from '../common/ErrorTable'
import { PurchaseRequest } from '../../types/purchase.types'
import { useEmployeePastPurchaseRequests, useListenToEmployeePastPurchaseRequests } from '../../hooks/usePurchase'
import PurchaseExpandableContent from './PurchaseExpandableContent'

const PurchaseManagerPastList: React.FC = () => {
  const { t } = useTranslation()

  // Start the real-time listener
  useListenToEmployeePastPurchaseRequests()

  // Get the list of past purchase requests
  const { data: pastRequests, isLoading, error } = useEmployeePastPurchaseRequests()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error(error)
    return (
      <ErrorTable
        title={t('purchase.managerList.past.error.title')}
        description={t('purchase.managerList.past.error.description')}
        message={t('purchase.managerList.past.error.message')}
      />
    )
  }

  if (!pastRequests || pastRequests.length === 0) {
    return (
      <NoDataTable
        title={t('purchase.managerList.past.noPurchaseRequests.title')}
        description={t('purchase.managerList.past.noPurchaseRequests.description')}
        message={t('purchase.managerList.past.noPurchaseRequests.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        { data: t('purchase.managerList.past.header.costType'), colSpan: 2 }, // cost type
        { data: t('purchase.managerList.past.header.recommendedSupplier'), colSpan: 2 }, // recommended supplier
        t('purchase.managerList.past.header.price'), // price
        t('purchase.managerList.past.header.createdAt'), // created at
        t('purchase.managerList.past.header.status'), // status
        '', // approvers
      ]}
      data={pastRequests}
      getRowId={(purchaseRequest: PurchaseRequest) => purchaseRequest.id}
      renderRow={(purchaseRequest: PurchaseRequest) => [
        { data: t(`costTypeDropdown.${purchaseRequest.costType}`), colSpan: 2 }, // cost type
        { data: purchaseRequest.recommendedSupplier, colSpan: 2 }, // recommended supplier
        `${purchaseRequest.price}€`, // price
        timestampToDate(purchaseRequest.createdAt), // created at
        purchaseRequest.status, // status
        <ApproversStatus approversStatus={purchaseRequest.approvals} key={purchaseRequest.id} />, // approvers
      ]}
      // reason and attachments
      renderExpandableContent={(purchaseRequest: PurchaseRequest) => <PurchaseExpandableContent purchaseRequest={purchaseRequest} />}
    />
  )
}

export default PurchaseManagerPastList
