import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { useTranslation } from 'react-i18next'
import ComplaintList from '../../features/Complaint/ComplaintList'

const ComplaintsListPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PageTemplate title={t('complaints.approverPage.title')}>
      <ComplaintList />
    </PageTemplate>
  )
}

export default withCapabilities(ComplaintsListPage)
