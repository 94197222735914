import { Box, IconButton, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Download as DownloadIcon } from '@mui/icons-material'
import { PurchaseRequest } from '../../types/purchase.types'
import { AttachmentContainer, BodyCell, HeaderCell } from './PurchaseApproverExpandableContent.styled'
import { useTranslation } from 'react-i18next'
const PurchaseApproverExpandableContent = ({ purchaseRequest }: { purchaseRequest: PurchaseRequest }) => {
  const { t } = useTranslation()
  return (
    <TableContainer sx={{ padding: '30px' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <HeaderCell>{t('purchase.approverList.expandable.header.price')}</HeaderCell>
            <HeaderCell>{t('purchase.approverList.expandable.header.recommendedSupplier')}</HeaderCell>
            {purchaseRequest.comment && <HeaderCell>{t('purchase.approverList.expandable.header.comment')}</HeaderCell>}
            <HeaderCell>{t('purchase.approverList.expandable.header.attachments')}</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <BodyCell>{purchaseRequest.price}€</BodyCell>
            <BodyCell>{purchaseRequest.recommendedSupplier}</BodyCell>
            {purchaseRequest.comment && <BodyCell style={{ maxWidth: '100%' }}>{purchaseRequest.comment}</BodyCell>}

            <BodyCell>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: '20px' }}>
                {purchaseRequest.attachments?.map((attachment, index) => (
                  <AttachmentContainer key={`attachments-${index}`}>
                    <Typography variant="body2">{attachment.name}</Typography>
                    <a href={attachment.url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
                      <IconButton>
                        <DownloadIcon />
                      </IconButton>
                    </a>
                  </AttachmentContainer>
                ))}
              </Box>
            </BodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PurchaseApproverExpandableContent
