import React from 'react'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import TableSkeletonLoader from '../common/TableSkeleton'
import ActionButton from '../../components/Button/Button'
import NoDataTable from '../common/NoDataTable'
import ErrorTable from '../common/ErrorTable'
import { hasPreviousPendingPurchaseApprovals } from '../../services/approval.service'
import { useAtom } from 'jotai'
import { userAtom } from '../../stores/userAtom'
import { useApprovePurchaseRequest, useRejectPurchaseRequest } from '../../hooks/usePurchase'
import { useApproverPendingPurchaseRequests } from '../../hooks/usePurchase'
import { useListenToApproverPendingPurchaseRequests } from '../../hooks/usePurchase'
import { PurchaseRequest } from '../../types/purchase.types'
import { Box } from '@mui/material'
import PurchaseApproverExpandableContent from './PurchaseApproverExpandableContent'

const PurchaseApproverPendingList: React.FC = () => {
  const { t } = useTranslation()
  const [user] = useAtom(userAtom)

  // Start the real-time listener
  useListenToApproverPendingPurchaseRequests()

  // Get the list of pending purchase requests
  const { data: pendingRequests, isLoading, error } = useApproverPendingPurchaseRequests()

  const approve = useApprovePurchaseRequest()
  const reject = useRejectPurchaseRequest()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error(error)
    return (
      <ErrorTable
        title={t('purchase.approverList.pending.error.title')}
        description={t('purchase.approverList.pending.error.description')}
        message={t('purchase.approverList.pending.error.message')}
      />
    )
  }

  if (!pendingRequests || pendingRequests.length === 0) {
    return (
      <NoDataTable
        title={t('purchase.approverList.pending.noPurchaseRequests.title')}
        description={t('purchase.approverList.pending.noPurchaseRequests.description')}
        message={t('purchase.approverList.pending.noPurchaseRequests.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        t('purchase.approverList.pending.header.name'), // name
        { data: t('purchase.approverList.pending.header.subject'), colSpan: 3 }, // subject
        t('purchase.approverList.pending.header.costType'), // cost type
        t('purchase.approverList.pending.header.createdAt'), // created at
        '', // approve and reject buttons
      ]}
      data={pendingRequests}
      getRowId={(purchaseRequest: PurchaseRequest) => purchaseRequest.id}
      renderRow={(purchaseRequest: PurchaseRequest) => [
        purchaseRequest.user?.name || '', // name
        { data: purchaseRequest.subject, colSpan: 3 }, // subject
        t(`costTypeDropdown.${purchaseRequest.costType}`), // cost type
        timestampToDate(purchaseRequest.createdAt), // created at
        // approve and reject buttons
        <Box display="flex" gap={1} key={`approve-reject-buttons-${purchaseRequest.id}`}>
          <ActionButton
            key={`approve-button-${purchaseRequest.id}`}
            buttonType="approve"
            onClick={() => approve(purchaseRequest.id)}
            disabled={hasPreviousPendingPurchaseApprovals(purchaseRequest.approvals, user?.authorityGroup)}
          >
            {t('purchase.approverList.pending.approve')}
          </ActionButton>

          <ActionButton
            key={`reject-button-${purchaseRequest.id}`}
            buttonType="reject"
            onClick={() => reject(purchaseRequest.id)}
            disabled={hasPreviousPendingPurchaseApprovals(purchaseRequest.approvals, user?.authorityGroup)}
          >
            {t('purchase.approverList.pending.reject')}
          </ActionButton>
        </Box>,
      ]}
      // price, recommended supplier, comment, attachments
      renderExpandableContent={(purchaseRequest: PurchaseRequest) => <PurchaseApproverExpandableContent purchaseRequest={purchaseRequest} />}
    />
  )
}

export default PurchaseApproverPendingList
