import { AuthorityGroup } from '../types/authorityGroup.types'
import { TimeOffRequestFirestoreData, TimeOffStatus } from '../types/timeOff.types'
import { ApprovalFirestoreData } from '../types/approval.types'
import { BaseStatus } from '../types/base.types'
import { purchaseApprovalsOrder, timeOffApprovalsOrder } from '../constants/approvals.constants'
import { PurchaseRequestFirestoreData, PurchaseStatus } from '../types/purchase.types'

export const appendWithPendingTimeOffApprovalsAndSatus = (data: TimeOffRequestFirestoreData) => {
  const dataWithStatus = { ...data, status: TimeOffStatus.PENDING }
  return { ...dataWithStatus, approvals: preparePendingApprovals(timeOffApprovalsOrder) }
}

export const appendWithPendingPurchaseApprovalsAndSatus = (data: PurchaseRequestFirestoreData) => {
  const dataWithStatus = { ...data, status: PurchaseStatus.PENDING }
  return { ...dataWithStatus, approvals: preparePendingApprovals(purchaseApprovalsOrder) }
}

export const preparePendingApprovals = (authorityGroups: AuthorityGroup[]): ApprovalFirestoreData => {
  const approvalData: ApprovalFirestoreData = {} as ApprovalFirestoreData
  authorityGroups.forEach((authorityGroup: AuthorityGroup) => {
    approvalData[authorityGroup] = BaseStatus.PENDING
  })
  return approvalData
}

export const calculateRequestStatus = (approvals: ApprovalFirestoreData) => {
  const statuses = Object.values(approvals)
  if (statuses.includes(BaseStatus.REJECTED)) {
    return BaseStatus.REJECTED
  }
  return statuses.every((status) => status === BaseStatus.APPROVED) ? BaseStatus.APPROVED : BaseStatus.PENDING
}

export const getApprovalStatus = (approvals: ApprovalFirestoreData, authorityGroup: AuthorityGroup) => {
  return approvals[authorityGroup]
}

/**
 * Checks if all previous approvers have approved the request.
 *
 * @param {ApprovalFirestoreData} approvals - The approvals map for the request.
 * @param {AuthorityGroup} currentAuthorityGroup - The current authority group to check against.
 * @returns {boolean} True if all previous approvers have approved, false otherwise.
 */
export const hasPreviousPendingApprovals = (
  approvals: ApprovalFirestoreData,
  currentAuthorityGroup: AuthorityGroup | undefined,
  approvalsOrder: AuthorityGroup[]
): boolean => {
  if (!currentAuthorityGroup) {
    return false
  }
  const currentIndex = approvalsOrder.indexOf(currentAuthorityGroup)

  const previousApprovals = approvalsOrder.slice(0, currentIndex)
  return previousApprovals.some((authorityGroup) => getApprovalStatus(approvals, authorityGroup) === BaseStatus.PENDING)
}

export const hasPreviousPendingTimeOffApprovals = (approvals: ApprovalFirestoreData, currentAuthorityGroup: AuthorityGroup | undefined): boolean => {
  return hasPreviousPendingApprovals(approvals, currentAuthorityGroup, timeOffApprovalsOrder)
}

export const hasPreviousPendingPurchaseApprovals = (approvals: ApprovalFirestoreData, currentAuthorityGroup: AuthorityGroup | undefined): boolean => {
  return hasPreviousPendingApprovals(approvals, currentAuthorityGroup, purchaseApprovalsOrder)
}
