import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { useTranslation } from 'react-i18next'
import TimeOffApproverPendingList from '../../features/TimeOff/TimeOffApproverPendingList'
import TimeOffApproverPastList from '../../features/TimeOff/TimeOffApproverPastList'
import { userAtom } from '../../stores/userAtom'
import { useAtom } from 'jotai'
import { timeOffApprovalsOrder } from '../../constants/approvals.constants'

const TimeOffApproverPage: React.FC = () => {
  const [user] = useAtom(userAtom)
  const { t } = useTranslation()
  return (
    <>
      {/* Only show the page if the user is in authorized approvers */}
      {user?.authorityGroup && timeOffApprovalsOrder.includes(user.authorityGroup) && (
        <PageTemplate title={t('timeOff.approverPage.pending.title')}>
          <TimeOffApproverPendingList />
        </PageTemplate>
      )}

      <PageTemplate title={t('timeOff.approverPage.past.title')}>
        <TimeOffApproverPastList />
      </PageTemplate>
    </>
  )
}

export default withCapabilities(TimeOffApproverPage)
