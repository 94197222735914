import { Box, styled, Typography } from '@mui/material'

export const PageTemplateContainer = styled(Box)`
  margin: 0 auto;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const PageTitle = styled(Typography)`
  margin-bottom: 40px;
`
export const ChildrenContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2;
  justify-content: center;
  align-items: center;
`
