import React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DownloadIcon from '@mui/icons-material/Download'

interface ViewDownloadSectionProps {
  fileUrl: string
}

const ViewDownloadSection: React.FC<ViewDownloadSectionProps> = ({ fileUrl }) => {
  const handleView = () => {
    window.open(fileUrl, '_blank')
  }

  const handleDownload = async () => {
    const response = await fetch(fileUrl)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    let fileName = decodeURIComponent(new URL(fileUrl).pathname).split('/').pop()?.split('?')[0] || 'download'
    const nameParts = fileName.split('-')
    if (nameParts.length > 1) {
      const extension = nameParts.pop()?.split('.').pop()
      fileName = `${nameParts.join('-')}.${extension}`
    }
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <div>
      <VisibilityIcon onClick={handleView} style={{ cursor: 'pointer', marginRight: '10px' }} />
      <DownloadIcon onClick={handleDownload} style={{ cursor: 'pointer' }} />
    </div>
  )
}

export default ViewDownloadSection
