import React, { ReactNode } from 'react'
import Grid, { Grid2Props } from '@mui/material/Grid2'

interface ExtendedGrid2Props extends Grid2Props {
  children: ReactNode
}

export const GridContainer: React.FC<ExtendedGrid2Props> = ({ children, ...props }) => {
  return (
    <Grid container spacing={2} {...props}>
      {children}
    </Grid>
  )
}
