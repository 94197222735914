import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { useTranslation } from 'react-i18next'
import PurchaseRequestForm from '../../features/Purchases/PurchaseForm'
import PurchaseManagerPastList from '../../features/Purchases/PurchaseManagerPastList'

const PurchaseRequestPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageTemplate title={t('purchase.managerPage.formTitle')} contentWidth="boxed">
        <PurchaseRequestForm />
      </PageTemplate>
      <PageTemplate title={t('purchase.managerPage.pastListTitle')}>
        <PurchaseManagerPastList />
      </PageTemplate>
    </>
  )
}

export default withCapabilities(PurchaseRequestPage)
