import { QueryKey } from '@tanstack/react-query'

export const QueryKeys = {
  // Time-off requests
  TIME_OFF_REQUESTS: ['timeOff'] as QueryKey,
  PENDING_TIME_OFF_REQUESTS: ['timeOff', 'pending'] as QueryKey,
  PAST_TIME_OFF_REQUESTS: ['timeOff', 'past'] as QueryKey,

  // Complaint requests
  COMPLAINT_REQUESTS: ['complaint'] as QueryKey,

  // Purchase requests
  PURCHASE_REQUESTS: ['purchase'] as QueryKey,
  PENDING_PURCHASE_REQUESTS: ['purchase', 'pending'] as QueryKey,
  PAST_PURCHASE_REQUESTS: ['purchase', 'past'] as QueryKey,

  // Useful files
  USEFUL_FILES: ['usefulFiles'] as QueryKey,
  ACTIVE_USEFUL_FILES: ['usefulFiles', 'active'] as QueryKey,
  DELETED_USEFUL_FILES: ['usefulFiles', 'deleted'] as QueryKey,
} as const
