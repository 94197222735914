import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { TextField, TextFieldProps, MenuItem } from '@mui/material'
import withCapabilities from '../../hoc/withCapabilities'

/**
 * SelectInput Component
 *
 * A customizable select input component that integrates with react-hook-form for form control.
 *
 * Props:
 * - name: string (required) - The name of the form field
 * - control: Control<any> (required) - The react-hook-form control object
 * - options: { label: string; value: string }[] (required) - The options for the select input
 * - helperText: string (optional) - Helper text to display below the input
 * - ...other TextField props
 *
 * Usage:
 * <SelectInput
 *   name="email"
 *   control={control}
 *   label="Email"
 *   options={[
 *     { label: 'Option 1', value: 'option1' },
 *     { label: 'Option 2', value: 'option2' },
 *   ]}
 *   helperText="Select an option"
 * />
 */

interface ControlledSelectInputProps extends Omit<TextFieldProps, 'name' | 'type'> {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  options: { label: string; value: string }[]
}

const SelectInput: React.FC<ControlledSelectInputProps> = ({ name, control, options, helperText, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue="" // Ensures the input is always controlled
      render={({ field, fieldState: { error } }) => {
        const textFieldProps: TextFieldProps = {
          ...field,
          variant: 'outlined',
          fullWidth: true,
          error: !!error,
          helperText: error ? error.message : helperText || ' ', // Preserve space for the helper text or error message
          ...props,
        }

        return (
          <TextField {...textFieldProps} select>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )
      }}
    />
  )
}

export default withCapabilities(SelectInput)
