import React from 'react'
import { Typography, Box, BoxProps } from '@mui/material'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'

export interface ErrorPageProps extends BoxProps {
  title: string
  description: string
  message: string
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, description, message, ...props }) => {
  return (
    <Box
      sx={{ textAlign: 'center', py: 4 }}
      style={{
        ...props.style,
      }}
    >
      <SentimentVeryDissatisfiedIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body1">{message}</Typography>
    </Box>
  )
}

export default ErrorPage
