import dayjs from 'dayjs'
import { Timestamp } from 'firebase/firestore'

export const today = dayjs()

export const tomorrow = dayjs().add(1, 'day')

export const dateToTimestamp = (date: dayjs.Dayjs) => {
  return Timestamp.fromDate(date.toDate())
}

export const timeToTimestamp = (time: dayjs.Dayjs) => {
  return Timestamp.fromDate(time.toDate())
}

export const timestampToDate = (date: Timestamp) => {
  if (!date || typeof date !== 'object') {
    return ''
  }
  return dayjs(date.toDate()).format('DD/MM/YYYY')
}

export const timestampToTime = (time: Timestamp) => {
  if (!time || typeof time !== 'object') {
    return ''
  }
  return dayjs(time.toDate()).format('HH:mm')
}
