import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { userAtom } from '../stores/userAtom'
import { isAuthorized } from '../utils/auth.utils'

interface PublicRouteProps {
  children: React.ReactElement
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const [user] = useAtom(userAtom)

  if (user && user?.authorityGroup && isAuthorized(user.authorityGroup)) {
    return <Navigate to={'/'} />
  }

  return children
}

export default PublicRoute
