import React from 'react'

import withCapabilities from '../../hoc/withCapabilities'

import PageTemplate from '../../components/PageTemplate/PageTemplate'

import ComplaintForm from '../../features/Complaint/ComplaintForm'
import { useTranslation } from 'react-i18next'

const ComplaintPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PageTemplate title={t('complaints.employeePage.title')} contentWidth="boxed">
      <ComplaintForm />
    </PageTemplate>
  )
}

export default withCapabilities(ComplaintPage)
