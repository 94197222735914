import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { useTranslation } from 'react-i18next'
import UsefulFilesUploaderForm from '../../features/UsefulFiles/UsefulFilesUploaderForm'
import UsefulFilesUploaderPastList from '../../features/UsefulFiles/UsefulFilesUploaderList'

const UsefulFilesUploadPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTemplate title={t('usefulFiles.uploaderPage.formTitle')} contentWidth="boxed">
        <UsefulFilesUploaderForm />
      </PageTemplate>

      <PageTemplate title={t('usefulFiles.uploaderPage.listTitle')}>
        <UsefulFilesUploaderPastList />
      </PageTemplate>
    </>
  )
}

export default withCapabilities(UsefulFilesUploadPage)
