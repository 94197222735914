import { Timestamp } from 'firebase/firestore'
import { UserDetails } from './userDetails.types'

// Complaint represents the fully constructed data used in the UI, which includes Firestore data and additional details
export type UsefulFile = UsefulFileFirestoreData & {
  user: UserDetails | null // Full user details object to include in the complaint
}

// UsefulFileFirestoreData represents the data stored in Firestore, which includes UsefulFileFormData
export type UsefulFileFirestoreData = {
  id: string // Unique identifier for the complaint, fetched from Firestore
  createdAt: Timestamp // Date of submission
  userId: string // Reference to the user who submitted the complaint
  status: UsefulFileStatus
  name: string
  url: string
  size: number
  type: string
}

// UsefulFileFormData represents the form data submitted by the user
export type UsefulFileFormData = {
  files: File[]
}

export const UsefulFileStatus = {
  ACTIVE: 'active',
  DELETED: 'deleted',
} as const

export type UsefulFileStatus = (typeof UsefulFileStatus)[keyof typeof UsefulFileStatus]
