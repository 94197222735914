import React from 'react'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import TableSkeletonLoader from '../common/TableSkeleton'
import { TimeOffRequest } from '../../types/timeOff.types'
import { Typography } from '@mui/material'
import { useListenToApproverPastTimeoffRequests, useApproverPastTimeoffRequests } from '../../hooks/useTimeOff' // Import the hooks and functions
import ApproversStatus from '../common/ApproversStatus'
import NoDataTable from '../common/NoDataTable'
import ErrorTable from '../common/ErrorTable'

const TimeOffApproverPastList: React.FC = () => {
  const { t } = useTranslation()

  // Start the real-time listener
  useListenToApproverPastTimeoffRequests()

  // Get the list of pending time-off requests
  const { data: pastRequests, isLoading, error } = useApproverPastTimeoffRequests()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error(error)
    return (
      <ErrorTable
        title={t('timeOff.approverList.past.error.title')}
        description={t('timeOff.approverList.past.error.description')}
        message={t('timeOff.approverList.past.error.message')}
      />
    )
  }

  if (!pastRequests || pastRequests.length === 0) {
    return (
      <NoDataTable
        title={t('timeOff.approverList.past.noTimeOffRequests.title')}
        description={t('timeOff.approverList.past.noTimeOffRequests.description')}
        message={t('timeOff.approverList.past.noTimeOffRequests.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        t('timeOff.approverList.past.header.name'),
        t('timeOff.approverList.past.header.division'),
        t('timeOff.approverList.past.header.role'),
        t('timeOff.approverList.past.header.startDate'),
        t('timeOff.approverList.past.header.endDate'),
        t('timeOff.approverList.past.header.type'),
        t('timeOff.approverList.past.header.amount'),
        t('timeOff.approverList.past.header.createdAt'),
        t('timeOff.approverList.past.header.status'),
        '', //  approvers
      ]}
      data={pastRequests}
      getRowId={(timeOffRequest: TimeOffRequest) => timeOffRequest.id}
      renderRow={(timeOffRequest: TimeOffRequest) => [
        timeOffRequest.user?.name || '',
        timeOffRequest.user?.division || '',
        timeOffRequest.user?.role || '',
        timestampToDate(timeOffRequest.startDate),
        timestampToDate(timeOffRequest.endDate),
        timeOffRequest.type,
        timeOffRequest.amount,
        timestampToDate(timeOffRequest.createdAt),
        timeOffRequest.status,
        <ApproversStatus approversStatus={timeOffRequest.approvals} key={timeOffRequest.id} />,
      ]}
      renderExpandableContent={(timeOffRequest: TimeOffRequest) => <Typography sx={{ padding: '30px' }}>{timeOffRequest.reason}</Typography>}
    />
  )
}

export default TimeOffApproverPastList
