import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

export const StyledDropzoneArea = styled(Box)`
  border: 1px dashed;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f9f9f9;
  margin-top: 1;
`

export const InfoText = styled(Typography)`
  margin-right: 8px;
`
export const StyledHelperText = styled(Box)`
  margin: 3px 14px 0 14px;
`

export const FileItem = styled(Box)`
  margin-top: 1;
  display: flex;
  align-items: center;
`
