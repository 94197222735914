import { createTheme, darken, lighten } from '@mui/material/styles'
// Import Raleway font weights from @fontsource
import '@fontsource/raleway/400.css' // Regular weight
import '@fontsource/raleway/500.css' // Medium weight
import '@fontsource/raleway/700.css' // Bold weight

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      lightBackground: string
    }
  }
  interface PaletteOptions {
    custom?: {
      lightBackground?: string
    }
  }
}

const primary = '#CBB483'
const secondary = '#432C2C'
const success = '#4caf50'
const error = '#f44336'
const contrastText = '#ffffff'
const background = '#f4f4f4'

const theme = createTheme({
  /*------- Palette ----------*/
  palette: {
    primary: {
      main: primary,
      light: lighten(primary, 0.2),
      dark: darken(primary, 0.2),
    },
    secondary: {
      main: secondary,
      light: lighten(secondary, 0.2),
      dark: darken(secondary, 0.2),
      contrastText,
    },
    success: {
      main: success,
      contrastText,
    },
    error: {
      main: error,
      contrastText,
    },
    background: {
      default: background,
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
    custom: {
      lightBackground: '#e7e7e7',
    },
  },
  /*------- Breakpoints ----------*/
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 600,
      laptop: 960,
      desktop: 1280,
    },
  },
  /*------- Typography ----------*/
  typography: {
    fontFamily: 'Raleway, Arial, sans-serif',
    h1: { fontSize: '2.5rem' },
    h2: { fontSize: '2rem' },
    h3: { fontSize: '1.75rem' },
    h4: { fontSize: '1.5rem' },
    h5: { fontWeight: 600 },
    button: { textTransform: 'none' },
  },
  /*------- Components ----------*/
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: ({ theme }) => ({
          fontSize: '2.5rem',
          [theme.breakpoints.up('tablet')]: { fontSize: '3rem' },
          [theme.breakpoints.up('laptop')]: { fontSize: '3.5rem' },
          [theme.breakpoints.up('desktop')]: { fontSize: '4rem' },
        }),
        h2: ({ theme }) => ({
          fontSize: '2rem',
          [theme.breakpoints.up('tablet')]: { fontSize: '2.5rem' },
          [theme.breakpoints.up('laptop')]: { fontSize: '3rem' },
          [theme.breakpoints.up('desktop')]: { fontSize: '3.5rem' },
        }),
        h3: ({ theme }) => ({
          fontSize: '1.75rem',
          [theme.breakpoints.up('tablet')]: { fontSize: '2rem' },
          [theme.breakpoints.up('laptop')]: { fontSize: '2.5rem' },
          [theme.breakpoints.up('desktop')]: { fontSize: '3rem' },
        }),
        h4: ({ theme }) => ({
          fontSize: '1.5rem',
          [theme.breakpoints.up('tablet')]: { fontSize: '1.7rem' },
          [theme.breakpoints.up('laptop')]: { fontSize: '1.75rem' },
          [theme.breakpoints.up('desktop')]: { fontSize: '2rem' },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Custom border radius for buttons
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false // removes the `xs` breakpoint
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true // adds the `mobile` breakpoint
    tablet: true
    laptop: true
    desktop: true
  }
}

export default theme
