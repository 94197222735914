import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import { ChildrenContainer, PageTemplateContainer, PageTitle } from './PageTemplate.styled'

type TitleAlignment = 'center' | 'left'

const PageTemplate: React.FC<{ title: string; titleAlignment?: TitleAlignment; contentWidth?: 'full' | 'boxed'; children: React.ReactNode }> = ({
  title,
  titleAlignment = 'center',
  contentWidth = 'full',
  children,
}) => {
  const titleAlignmentStyle = titleAlignment === 'center' ? { alignSelf: 'center' } : { alignSelf: 'flex-start' }
  const contentWidthStyle = contentWidth === 'boxed' ? { maxWidth: { tablet: '1000px' }, width: { tablet: '80%' } } : {}
  return (
    <PageTemplateContainer>
      <PageTitle variant="h4" sx={titleAlignmentStyle}>
        {title}
      </PageTitle>
      <ChildrenContainer sx={contentWidthStyle}>{children}</ChildrenContainer>
    </PageTemplateContainer>
  )
}

export default withCapabilities(PageTemplate)
