import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import withCapabilities from '../../hoc/withCapabilities'

/**
 * ActionButton Component
 *
 * A customizable button component with predefined styles for different actions and sizes.
 *
 * Available button types:
 * - 'primary' (default): Primary action button (contained, primary color)
 * - 'approve': Approval button (outlined, success color)
 * - 'reject': Rejection button (outlined, error color)
 * - 'secondary': Secondary action button (contained, secondary color)
 *
 * Available sizes:
 * - 'small': Small-sized button
 * - 'medium': Medium-sized button
 * - 'large': Large-sized button
 * - 'fullWidth' (default): Full-width button
 *
 * Usage:
 * <ActionButton buttonType="approve" size="medium">Approve</ActionButton>
 */

interface ActionButtonProps extends Omit<ButtonProps, 'size'> {
  buttonType?: 'primary' | 'approve' | 'reject' | 'secondary'
  size?: 'small' | 'medium' | 'large' | 'fullWidth'
}

const ActionButton: React.FC<ActionButtonProps> = ({ children, buttonType = 'action', size: buttonSize = 'fullWidth', ...props }) => {
  let variant: 'contained' | 'outlined'
  let color: 'primary' | 'secondary' | 'error' | 'success'

  switch (buttonType) {
    case 'approve':
      variant = 'outlined'
      color = 'success'
      break
    case 'reject':
      variant = 'outlined'
      color = 'error'
      break
    case 'secondary':
      variant = 'outlined'
      color = 'secondary'
      break
    default: //primary
      variant = 'contained'
      color = 'primary'
  }

  const buttonProps = {
    variant,
    color,
    ...(buttonSize === 'fullWidth' ? { style: { width: '100%' } } : { size: buttonSize }),
    ...props,
  }

  return <Button {...buttonProps}>{children}</Button>
}

export default withCapabilities(ActionButton)
