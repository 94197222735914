import React from 'react'
import { Typography } from '@mui/material'
import { StyledHelperText } from './FileUploader.styled'
import { FieldError } from 'react-hook-form'

interface HelperTextProps {
  helperText?: string
  error?: FieldError | undefined
}

const HelperText: React.FC<HelperTextProps> = ({ helperText, error }) => {
  return (
    <StyledHelperText>
      {/*-------------------- Helper Text -------------------- */}
      {helperText && !error && <Typography variant="caption">{helperText}</Typography>}

      {/*-------------------- Validation Errors -------------------- */}
      {error && (
        <Typography color="error" variant="caption">
          {error.message}
        </Typography>
      )}
    </StyledHelperText>
  )
}

export default HelperText
