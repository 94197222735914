import React, { useState } from 'react'
import { IconButton, Drawer, List, ListItemButton, ListItemText, Box, BoxProps, MenuItem } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import withCapabilities from '../../../hoc/withCapabilities'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { MenuItem as MenuItemType } from '../../../types/menuItem.types'

interface MobileMenuProps extends BoxProps {
  menuItems: MenuItemType[]
  openFrom: 'left' | 'right'
}

const SideBarMenu: React.FC<MobileMenuProps> = ({ menuItems, openFrom, ...props }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { logout } = useAuth()
  const { t } = useTranslation()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Box {...props}>
      <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
        <MenuIcon />
      </IconButton>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor={openFrom}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { mobile: 'block', laptop: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItemButton key={item.url} component={Link} to={item.url} onClick={handleDrawerToggle}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
        </List>
        <MenuItem sx={{ position: 'absolute', bottom: 0, width: '100%' }} onClick={logout}>
          {t('header.logout')}
        </MenuItem>
      </Drawer>
    </Box>
  )
}

export default withCapabilities(SideBarMenu)
