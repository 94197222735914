import React from 'react'
import { Cell, Divider, Row } from './ExpandableTable.styled'

interface RowDividerProps {
  colSpan?: number
}

const RowDivider: React.FC<RowDividerProps> = ({ colSpan }) => {
  return (
    <Row key={'devider'}>
      <Cell colSpan={colSpan} style={{ padding: 0 }}>
        <Divider />
      </Cell>
    </Row>
  )
}

export default RowDivider
