import React from 'react'
import { Typography, Box, IconButton } from '@mui/material'
import { FileItem } from './FileUploader.styled'
import DeleteIcon from '@mui/icons-material/Delete'
import { ControllerRenderProps } from 'react-hook-form'

interface UploadedFilesProps {
  uploadedFiles: File[]
  t: (key: string) => string
  handleDeleteFile: (file: File, field: ControllerRenderProps) => void
  field: ControllerRenderProps
}

const UploadedFiles: React.FC<UploadedFilesProps> = ({ uploadedFiles, t, handleDeleteFile, field }) => {
  return (
    <>
      {uploadedFiles.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6">{t('fileUploader.uploadedFilesTitle')}</Typography>
          {uploadedFiles.map((file, index) => (
            <FileItem key={index}>
              <Typography variant="body1">{file.name}</Typography>
              <IconButton onClick={() => handleDeleteFile(file, field)} size="small">
                <DeleteIcon />
              </IconButton>
            </FileItem>
          ))}
        </Box>
      )}
    </>
  )
}

export default UploadedFiles
