export enum AuthorityGroups {
  EMPLOYEE = 'employee',
  HR = 'hr',
  CFO = 'cfo',
  CEO = 'ceo',
  GM = 'gm',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export type AuthorityGroup =
  | AuthorityGroups.EMPLOYEE
  | AuthorityGroups.HR
  | AuthorityGroups.CFO
  | AuthorityGroups.CEO
  | AuthorityGroups.GM
  | AuthorityGroups.ADMIN
  | AuthorityGroups.MANAGER
