import React from 'react'
import { useDropzone } from 'react-dropzone'
import { ControllerRenderProps, FieldError } from 'react-hook-form'
import { StyledDropzoneArea, InfoText } from './FileUploader.styled'
import ActionButton from '../Button/Button'
import theme from '../../theme'

interface DropZoneAreaProps {
  error: FieldError | undefined
  uploadedFilesRef: React.MutableRefObject<File[]>
  field: ControllerRenderProps
  handleDrop: (files: File[]) => void
  t: (key: string) => string
}

const DropZoneAreaComponent: React.FC<DropZoneAreaProps> = ({ error, uploadedFilesRef, field, handleDrop, t }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles),
  })

  return (
    <StyledDropzoneArea
      {...getRootProps()}
      sx={{
        borderColor: error ? theme.palette.error.main : '#ccc',
      }}
    >
      <input
        {...getInputProps()}
        onChange={(e) => {
          const files = Array.from(e.target.files || [])
          const newFiles = [...uploadedFilesRef.current, ...files]
          field.onChange(newFiles)
          handleDrop(files)
        }}
        multiple
      />
      <InfoText variant="body1" color={error ? theme.palette.error.main : 'inherit'}>
        {t('fileUploader.infoText')}
      </InfoText>
      <ActionButton buttonType="secondary" size="small">
        {t('fileUploader.buttonText')}
      </ActionButton>
    </StyledDropzoneArea>
  )
}

export default DropZoneAreaComponent
