import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import TextInput from '../../components/InputFields/TextInput'
import ActionButton from '../../components/Button/Button'
import { toast } from 'react-toastify'
import { timeOffRequestValidationSchema } from '../../utils/formValidations.utils'
import withCapabilities from '../../hoc/withCapabilities'
import { useTranslation } from 'react-i18next'
import { dateToTimestamp } from '../../utils/dateTime.utils'
import DateInput from '../../components/InputFields/DateInput'
import SelectInput from '../../components/InputFields/SelectInput'
import Grid from '@mui/material/Grid2'
import { TimeOffRequestFormData } from '../../types/timeOff.types'
import { useSubmitTimeOffRequest } from '../../hooks/useTimeOff'
import { StyledForm } from '../../App.styles'
import { GridContainer } from '../../components/Grid/GridContainer'

type FormData = z.infer<typeof timeOffRequestValidationSchema>

const TimeOffRequestForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
    watch,
  } = useForm<FormData>({
    resolver: zodResolver(timeOffRequestValidationSchema),
  })

  useEffect(() => {
    reset()
  }, [isSubmitSuccessful])

  const { t } = useTranslation()

  const { mutate } = useSubmitTimeOffRequest()

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true)

    // Prepare data to store in Firestore
    const timeOffRequestData: TimeOffRequestFormData = {
      ...data,
      startDate: dateToTimestamp(data.startDate),
      endDate: dateToTimestamp(data.endDate),
    }

    mutate(
      { data: timeOffRequestData },
      {
        onSuccess: () => {
          toast.success(t('timeOff.form.success'))
          setIsSubmitting(false)
        },
        onError: () => {
          toast.error(t('timeOff.form.error'))
          setIsSubmitting(false)
        },
      }
    )
  }

  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const amountHelperText =
    startDate && endDate ? (startDate.isSame(endDate) ? t('timeOff.form.amountHelperTextHours') : t('timeOff.form.amountHelperTextDays')) : ''

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <Grid container spacing={2} size={{ mobile: 12 }}>
          {/*--------------- Start Date ------------- */}
          <Grid size={{ mobile: 12, tablet: 6, desktop: 3 }}>
            <DateInput name="startDate" label={t('timeOff.form.startDate')} control={control} disablePast />
          </Grid>

          {/*--------------- End Date -------------- */}
          <Grid size={{ mobile: 12, tablet: 6, desktop: 3 }}>
            <DateInput name="endDate" label={t('timeOff.form.endDate')} control={control} disablePast />
          </Grid>
        </Grid>

        <Grid container spacing={2} size={{ mobile: 12 }}>
          {/*--------------- Type -------------- */}
          <Grid size={{ mobile: 12, tablet: 6, desktop: 3 }}>
            <SelectInput
              name="type"
              label={t('timeOff.form.type')}
              options={[
                { label: 'Holiday', value: 'holiday' },
                { label: 'Sick Leave', value: 'sickLeave' },
                { label: 'Parental Leave', value: 'parentalLeave' },
                { label: 'Voting Leave', value: 'votingLeave' },
                { label: 'Unpaid Leave', value: 'unpaidLeave' },
              ]}
              control={control}
            />
          </Grid>

          {/*--------------- Amount -------------- */}
          <Grid size={{ mobile: 12, tablet: 6, desktop: 3 }}>
            <TextInput name="amount" label={t('timeOff.form.amount')} helperText={amountHelperText} control={control} type="number" />
          </Grid>
        </Grid>

        {/*--------------- Reason -------------- */}
        <Grid size={12}>
          <TextInput
            name="reason"
            label={t('timeOff.form.reason')}
            helperText={t('timeOff.form.reasonHelperText')}
            control={control}
            multiline
            rows={12}
          />
        </Grid>

        {/*--------------- Submit Button -------------- */}
        <Grid size={12}>
          <ActionButton buttonType="primary" type="submit" size="fullWidth" disabled={isSubmitting}>
            {t('timeOff.form.submit')}
          </ActionButton>
        </Grid>
      </GridContainer>
    </StyledForm>
  )
}

export default withCapabilities(TimeOffRequestForm)
