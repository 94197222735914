import React from 'react'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import TableSkeletonLoader from '../common/TableSkeleton'
import ApproversStatus from '../common/ApproversStatus'
import NoDataTable from '../common/NoDataTable'
import ErrorTable from '../common/ErrorTable'
import { useApproverPastPurchaseRequests, useListenToApproverPastPurchaseRequests } from '../../hooks/usePurchase'
import { PurchaseRequest } from '../../types/purchase.types'
import PurchaseApproverExpandableContent from './PurchaseApproverExpandableContent'

const PurchaseApproverPastList: React.FC = () => {
  const { t } = useTranslation()

  // Start the real-time listener
  useListenToApproverPastPurchaseRequests()

  // Get the list of past purchase requests
  const { data: pastRequests, isLoading, error } = useApproverPastPurchaseRequests()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error(error)
    return (
      <ErrorTable
        title={t('purchase.approverList.past.error.title')}
        description={t('purchase.approverList.past.error.description')}
        message={t('purchase.approverList.past.error.message')}
      />
    )
  }

  if (!pastRequests || pastRequests.length === 0) {
    return (
      <NoDataTable
        title={t('purchase.approverList.past.noPurchaseRequests.title')}
        description={t('purchase.approverList.past.noPurchaseRequests.description')}
        message={t('purchase.approverList.past.noPurchaseRequests.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        t('purchase.approverList.past.header.name'), // name
        { data: t('purchase.approverList.past.header.subject'), colSpan: 3 }, // subject
        t('purchase.approverList.past.header.costType'), // cost type
        t('purchase.approverList.past.header.createdAt'), // created at
        t('purchase.approverList.past.header.status'), // status
        '', //  approvers
      ]}
      data={pastRequests}
      getRowId={(purchaseRequest: PurchaseRequest) => purchaseRequest.id}
      renderRow={(purchaseRequest: PurchaseRequest) => [
        purchaseRequest.user?.name || '', // name
        { data: purchaseRequest.subject, colSpan: 3 }, // subject
        t(`costTypeDropdown.${purchaseRequest.costType}`), // cost type
        timestampToDate(purchaseRequest.createdAt), // created at
        purchaseRequest.status,
        <ApproversStatus approversStatus={purchaseRequest.approvals} key={purchaseRequest.id} />,
      ]}
      renderExpandableContent={(purchaseRequest: PurchaseRequest) => <PurchaseApproverExpandableContent purchaseRequest={purchaseRequest} />}
    />
  )
}

export default PurchaseApproverPastList
