import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const MenuContainer = styled(Box)`
  display: flex;
  gap: 24px;
  a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`
