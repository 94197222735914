import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { useTranslation } from 'react-i18next'
import UsefulFilesList from '../../features/UsefulFiles/UsefulFilesViewerList'

const UsefulFilesDownloadPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageTemplate title={t('usefulFiles.viewerPage.listTitle')}>
        <UsefulFilesList />
      </PageTemplate>
    </>
  )
}

export default withCapabilities(UsefulFilesDownloadPage)
