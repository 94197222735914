import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import TextInput from '../../components/InputFields/TextInput'
import ActionButton from '../../components/Button/Button'
import { toast } from 'react-toastify'
import { purchaseRequestValidationSchema } from '../../utils/formValidations.utils'
import withCapabilities from '../../hoc/withCapabilities'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../components/InputFields/SelectInput'
import Grid from '@mui/material/Grid2'
import { CostTypeOptions } from '../../constants/costType.constants'
import { useSubmitPurchaseRequest } from '../../hooks/usePurchase'
import FileUploader from '../../components/FileUploader/FileUploader'
import { StyledForm } from '../../App.styles'
import { GridContainer } from '../../components/Grid/GridContainer'

type FormData = z.infer<typeof purchaseRequestValidationSchema>

const PurchaseRequestForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: zodResolver(purchaseRequestValidationSchema),
  })

  useEffect(() => {
    reset()
  }, [isSubmitSuccessful])

  const { t } = useTranslation()

  const { mutate } = useSubmitPurchaseRequest()

  const onSubmit = async (data: FormData) => {
    setIsSubmitting(true)
    mutate(
      { data },
      {
        onSuccess: () => {
          toast.success(t('purchase.form.success'))
          reset()
          setIsSubmitting(false)
        },
        onError: () => {
          toast.error(t('purchase.form.error'))
          setIsSubmitting(false)
        },
      }
    )
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        {/*--------------- Subject -------------- */}
        <Grid size={{ mobile: 12 }}>
          <TextInput name="subject" label={t('purchase.form.subject')} helperText={t('purchase.form.subjectHelperText')} control={control} />
        </Grid>

        {/*--------------- Cost Type -------------- */}
        <Grid size={{ mobile: 12, tablet: 12, desktop: 5 }}>
          <SelectInput
            name="costType"
            label={t('purchase.form.costType')}
            options={Object.values(CostTypeOptions).map((costType) => ({
              label: t(`costTypeDropdown.${costType}`),
              value: costType,
            }))}
            control={control}
          />
        </Grid>

        {/*--------------- Price -------------- */}
        <Grid size={{ mobile: 12, tablet: 12, desktop: 3 }}>
          <TextInput name="price" label={t('purchase.form.price')} helperText={t('purchase.form.priceHelperText')} control={control} type="number" />
        </Grid>

        {/*--------------- Recommended Supplier -------------- */}
        <Grid size={{ mobile: 12, tablet: 12, desktop: 4 }}>
          <TextInput
            name="recommendedSupplier"
            label={t('purchase.form.recommendedSupplier')}
            helperText={t('purchase.form.recommendedSupplierHelperText')}
            control={control}
          />
        </Grid>

        {/*--------------- Comment -------------- */}
        <Grid size={{ mobile: 12 }}>
          <TextInput
            name="comment"
            label={t('purchase.form.comment')}
            helperText={t('purchase.form.commentHelperText')}
            control={control}
            multiline
            rows={3}
          />
        </Grid>

        {/*--------------- Attachments -------------- */}
        <Grid size={{ mobile: 12 }}>
          <FileUploader
            name="attachments"
            label={t('purchase.form.attachments')}
            helperText={t('purchase.form.attachmentsHelperText')}
            control={control}
          />{' '}
        </Grid>

        {/*--------------- Submit Button -------------- */}
        <Grid size={{ mobile: 12 }}>
          <ActionButton buttonType="primary" type="submit" size="fullWidth" disabled={isSubmitting}>
            {t('purchase.form.submit')}
          </ActionButton>
        </Grid>
      </GridContainer>
    </StyledForm>
  )
}

export default withCapabilities(PurchaseRequestForm)
