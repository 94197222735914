import React, { useState } from 'react'
import { Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material'
import withCapabilities from '../../hoc/withCapabilities'
import RowDivider from './RowDivider'
import CollapsibleTableRow from './CollapsibleRow'
import { BoldTypography, Cell, iconWidth, Row } from './ExpandableTable.styled'
import { useIsMobile } from '../../hooks/useScreenSize'
import { cellType, rowLength, headerType } from './table.utils'

interface ExpandableTableProps<T> {
  headers: headerType[]
  data: T[]
  getRowId: (item: T) => string
  renderRow: (item: T) => cellType[]
  renderExpandableContent?: (item: T) => React.ReactNode
}

const ExpandableTable = <T,>({ headers, data, getRowId, renderRow, renderExpandableContent }: ExpandableTableProps<T>) => {
  const isMobile = useIsMobile()
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({})

  const handleToggle = (rowId: string) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }))
  }

  /**
   * Renders the table headers.
   * If a header is an object {data: string, colSpan: number}, it will render a cell with a colspan.
   * Otherwise, it will render a simple cell.
   *
   * @returns {React.ReactNode[]} An array of table header cells.
   */
  const renderHeaders = () => {
    return headers.map((header, index) => {
      if (typeof header === 'object') {
        return (
          <Cell key={index + header.data} colSpan={header?.colSpan || 1}>
            <BoldTypography variant="subtitle2">{header.data}</BoldTypography>
          </Cell>
        )
      } else {
        return (
          <Cell key={index + header}>
            <BoldTypography variant="subtitle2">{header}</BoldTypography>
          </Cell>
        )
      }
    })
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ tableLayout: isMobile ? 'auto' : 'fixed' }}>
        {/* ---------------------- Header ------------------------- */}
        <TableHead>
          <Row key={'header'}>
            {/* ----------------Icon Empty Cell ---------------- */}
            {renderExpandableContent && <Cell key={'icon'} width={iconWidth} />}
            {/*-------------------- headers --------------------*/}
            {renderHeaders()}
          </Row>
          {/*---------------------  Devider ------------------- */}
          <RowDivider colSpan={renderExpandableContent ? rowLength(headers) + 1 : rowLength(headers)} />
        </TableHead>

        {/* ---------------------- Body -------------------------- */}
        <TableBody>
          {data.map((item) => {
            const rowId = getRowId(item)
            return (
              // ---------------------- Row --------------------------
              <CollapsibleTableRow
                key={rowId}
                rowId={rowId}
                isOpen={openRows[rowId] || false}
                onToggle={handleToggle}
                columns={renderRow(item)}
                expandableContent={renderExpandableContent ? renderExpandableContent(item) : null}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withCapabilities(ExpandableTable)
